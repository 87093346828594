import {Orchestrator} from '@amzn/f3-excelsior-orchestrator-api';
import {AsyncStatus, useAsync, useExecute} from './use-async';
import {BusinessContext, RegionId, ServiceResource, ServiceEndpoint} from './use-auth';
import {DependencyList, useCallback, useEffect, useState} from 'react';
import {wrapOnErrorListener} from '../common';

/**
 * Documentation on aws-sdk javascript
 *
 * https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/working-with-services.html
 * https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/calling-services-asynchronously.html
 * https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/using-async-await.html
 * https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/using-promises.html
 *
 * The hook pattern here is that we delegating the clientConfiguration to the caller
 * on the top-level call pattern that specific to a BusinessContext.
 *
 * There is only one exception that is ListDashboardEvents, because we use it
 * to call multiple businesses at once.
 * We will wrap the calls such it follows hook rules (i.e., no loop over hooks)
 * https://reactjs.org/docs/hooks-rules.html
 */

/* eslint-disable react-hooks/exhaustive-deps */
export function useListUpcomingEvents(
    clientConfiguration: Orchestrator.Types.ClientConfiguration,
    request: Orchestrator.Types.ListUpcomingEventsRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new Orchestrator(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.listUpcomingEvents(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function useStart(
    clientConfiguration: Orchestrator.Types.ClientConfiguration,
    request: Orchestrator.Types.StartRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new Orchestrator(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.start(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function useStop(
    clientConfiguration: Orchestrator.Types.ClientConfiguration,
    request: Orchestrator.Types.StopRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new Orchestrator(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.stop(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function usePause(
    clientConfiguration: Orchestrator.Types.ClientConfiguration,
    request: Orchestrator.Types.PauseRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new Orchestrator(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.pause(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function useDeleteEvents(
    clientConfiguration: Orchestrator.Types.ClientConfiguration,
    request: Orchestrator.Types.DeleteEventsRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new Orchestrator(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.deleteEvents(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function useRescheduleEvents(
    clientConfiguration: Orchestrator.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new Orchestrator(clientConfiguration)
) {
    return useExecute(
        useCallback(
            (request: Orchestrator.Types.RescheduleEventsRequest) =>
                wrapOnErrorListener(client.rescheduleEvents(request).promise(), onErrorListener),
            deps
        )
    );
}

export function usePutSchedule(
    clientConfiguration: Orchestrator.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new Orchestrator(clientConfiguration)
) {
    return {
        execute: useCallback(
            (request: Orchestrator.Types.PutScheduleRequest) =>
                wrapOnErrorListener(client.putSchedule(request).promise(), onErrorListener),
            deps
        ),
    };
}

export function useDescribeSchedule(
    clientConfiguration: Orchestrator.Types.ClientConfiguration,
    request: Orchestrator.Types.DescribeScheduleRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new Orchestrator(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.describeSchedule(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function useStartAdhocEvent(
    clientConfiguration: Orchestrator.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new Orchestrator(clientConfiguration)
) {
    return useExecute(
        useCallback(
            (request: Orchestrator.Types.StartAdhocExecutionRequest) =>
                wrapOnErrorListener(client.startAdhocExecution(request).promise(), onErrorListener),
            deps
        )
    );
}

export interface DashboardEventFetchStatus {
    // putting BusinessContext maybe too much
    // BusinessContext has more than just country/flow/country
    businessContext: BusinessContext;
    status: AsyncStatus;
    error?: any;
    // empty if error
    schedulerStatus?: Orchestrator.Types.ScheduleStatus;
    events: Orchestrator.Types.DashboardEvents;
}

export function useListDashboardEvents(
    endpoints: Record<RegionId, Record<ServiceResource, ServiceEndpoint>>,
    businessContexts: BusinessContext[],
    startTime: Date,
    endTime: Date,
    immediate: boolean,
    clientFactory = (clientConfiguration: Orchestrator.Types.ClientConfiguration) => new Orchestrator(clientConfiguration)
) {
    const [value, setValue] = useState(
        businessContexts.map(
            (businessContext) =>
                ({
                    businessContext,
                    status: 'idle',
                    events: [],
                } as DashboardEventFetchStatus)
        )
    );

    // filter out all non-match and add the match
    // the problem is the resulting order is changed
    // TODO: fix the issue; good to prototype
    const update = (
        businessContext: BusinessContext,
        status: AsyncStatus,
        events: Orchestrator.Types.DashboardEvents,
        schedulerStatus?: Orchestrator.Types.ScheduleStatus,
        error?: string
    ) => {
        return (prevValue: DashboardEventFetchStatus[]) =>
            prevValue
                .filter(
                    (v) =>
                        v.businessContext.businessId !== businessContext.businessId ||
                        v.businessContext.country !== businessContext.country ||
                        v.businessContext.flow !== businessContext.flow
                )
                .concat([
                    {
                        businessContext,
                        status,
                        events,
                        schedulerStatus,
                        error,
                    },
                ]);
    };

    const refreshSingle = useCallback(
        async (businessContext: BusinessContext) => {
            const orchestratorServiceConfig = endpoints[businessContext.regionId].OrchestratorView;
            const client = clientFactory({
                endpoint: orchestratorServiceConfig.endpoint,
                credentials: orchestratorServiceConfig.credentials,
                region: orchestratorServiceConfig.region,
            });

            try {
                setValue(update(businessContext, 'pending', []));
                const response = await client
                    .listDashboardEvents({
                        businessId: businessContext.businessId,
                        country: businessContext.country,
                        flow: businessContext.flow,
                        startTime,
                        endTime,
                    })
                    .promise();
                setValue(update(businessContext, 'success', response.events, response.status));
            } catch (error: any) {
                setValue(update(businessContext, 'error', [], undefined, error));
            }
        },
        [endpoints, startTime, endTime]
    );

    const refreshAll = useCallback(async () => {
        for (const businessContext of businessContexts) {
            await refreshSingle(businessContext);
        }
    }, [businessContexts, refreshSingle]);

    useEffect(() => {
        if (immediate) {
            refreshAll();
        }
    }, [immediate, refreshAll]);

    return {
        dashboardEventFetchStatus: value,
        refreshAll,
        refreshSingle,
    };
}
/* eslint-enable react-hooks/exhaustive-deps */
