import React from 'react';
import {useRouter} from '../hooks/use-router';
import {useAuth} from '../hooks/use-auth';
import {TopNavigation, TopNavigationProps} from '@amzn/awsui-components-react';
import {getFlowDisplayNamesByBusinessAndFlow} from '../common/utils';
import '../../assets/styles/main-styles.scss';
import {getBusinessIdDisplayNames} from '../business-selection/business-selection';

export default function TopNavigationBarV2() {
    const auth = useAuth();
    const router = useRouter();

    const navButtons: TopNavigationProps.Utility[] = [];

    const canSeeAnalyticsMenuItem =
        auth.authInformation?.current?.permissions.canViewAccuracyDashboard ||
        auth.authInformation?.current?.configurationData?.insightsEnabledOnPortal ||
        (auth.authInformation?.globalScopes &&
            (auth.authInformation?.globalScopes['oncallOperations']?.split(',').includes('manage') ?? false));

    const analyticsItems: any[] = [];

    if (auth.authInformation?.current?.permissions.canViewAccuracyDashboard) {
        analyticsItems.push({
            id: 'accuracy',
            text: 'Accuracy',
            href: 'accuracy-dashboard',
        });
    }

    if (auth.authInformation?.current?.configurationData?.insightsEnabledOnPortal) {
        analyticsItems.push({
            id: 'explain',
            text: 'Model Explainability',
            href: 'explain',
        });
    }

    if (
        auth.authInformation?.globalScopes &&
        (auth.authInformation?.globalScopes['oncallOperations']?.split(',').includes('manage') ?? false)
    ) {
        analyticsItems.push({
            id: 'insights',
            text: 'Insights',
            href: 'insights',
        });
    }

    if (
        auth.authInformation?.globalScopes &&
        (auth.authInformation?.globalScopes['operationalDashboard']?.split(',').includes('view') ?? false)
    ) {
        navButtons.push({
            type: 'button',
            text: 'Dashboard',
            ariaLabel: router.pathname === '/operational-dashboard' ? 'active' : 'operational-dashboard',
            onClick: () => router.push('/operational-dashboard'),
        });
    }

    if (auth.authInformation?.current?.permissions.canManageForecastWorkflows) {
        navButtons.push({
            type: 'button',
            text: 'Orchestrator',
            ariaLabel: router.pathname === '/operational-schedule' ? 'active' : 'operational-schedule',
            onClick: () => router.push('/operational-schedule'),
        });
    }

    if (auth.authInformation?.current?.permissions.canViewForecasts) {
        navButtons.push({
            type: 'button',
            text: 'View Forecasts',
            ariaLabel: router.pathname === '/forecast' ? 'active' : 'forecast',
            onClick: () => router.push('/forecast'),
        });
    }

    if (
        auth.authInformation?.current?.permissions.canViewForecasts &&
        auth.authInformation?.current?.configurationData?.forecastBuilderEnabledOnPortal
    ) {
        navButtons.push({
            type: 'button',
            text: 'Build Forecasts',
            ariaLabel: router.pathname === '/forecast-builder' ? 'active' : 'forecast-builder',
            onClick: () => router.push('/forecast-builder'),
        });
    }

    if (auth.authInformation?.current?.permissions.canUploadBulkOverrides) {
        navButtons.push({
            type: 'button',
            text: 'Bulk Overrides',
            ariaLabel: router.pathname === '/overrides' ? 'active' : 'overrides',
            onClick: () => router.push('/overrides'),
        });
    }

    if (auth.authInformation?.current?.permissions.canUploadMarketingInputs) {
        navButtons.push({
            type: 'button',
            text: 'Input Signals',
            ariaLabel: router.pathname === '/signals' ? 'active' : 'signals',
            onClick: () => router.push('/signals'),
        });
    }

    if (auth.authInformation?.current?.permissions.canViewForecasts) {
        navButtons.push({
            type: 'button',
            text: 'Forecast History',
            ariaLabel: router.pathname === '/history' ? 'active' : 'history',
            onClick: () => router.push('/history'),
        });
    }

    if (canSeeAnalyticsMenuItem) {
        navButtons.push({
            type: 'menu-dropdown',
            text: 'Analytics',
            items: analyticsItems,
            ariaLabel: ['/accuracy-dashboard', '/explain', '/insights'].includes(router.pathname) ? 'active' : 'analytics',
            onItemClick: async (e) => {
                e.preventDefault();
                router.push(`/${e.detail.href}`);
            },
        });
    }

    if (
        auth.authInformation?.globalScopes &&
        (auth.authInformation?.globalScopes['merchants']?.split(',').includes('view') ?? false)
    ) {
        navButtons.push({
            type: 'button',
            text: 'Stores',
            ariaLabel: router.pathname === '/stores' ? 'active' : 'stores',
            onClick: () => router.push('/stores'),
        });
    }

    navButtons.push({
        type: 'button',
        text: 'Support',
        ariaLabel: router.pathname === '/support' ? 'active' : 'support',
        onClick: () => router.push('/support'),
    });

    let businessDisplayName = '';
    if (auth.authInformation?.current) {
        businessDisplayName = `(${
            getBusinessIdDisplayNames(auth.authInformation?.current.businessId) + ' ' + auth.authInformation?.current.country
        })`;

        const flows = (auth.authInformation?.businessContexts || [])
            .filter((v) => v.businessId === auth.authInformation?.current?.businessId)
            .filter((v) => v.country === auth.authInformation?.current?.country)
            .map((v) => v.flow);

        if (flows.length > 1) {
            navButtons.unshift({
                type: 'menu-dropdown',
                text: `Flow - ${getFlowDisplayNamesByBusinessAndFlow(
                    auth.authInformation?.current?.businessId,
                    auth.authInformation?.current!.flow
                ).toUpperCase()}`,
                items: flows.map((flow) => {
                    return {
                        id: flow,
                        text: getFlowDisplayNamesByBusinessAndFlow(auth.authInformation!.current!.businessId, flow).toUpperCase(),
                    };
                }),
                onItemClick: async (e) => {
                    await auth.authInformation?.switchTo(
                        auth.authInformation?.current!.businessId,
                        auth.authInformation?.current!.country,
                        e.detail.id
                    );
                    router.push('/');
                },
            });
        }
    }

    return (
        <TopNavigation
            identity={{
                href: '',
                title: `EXCELSIOR ${businessDisplayName}`,
                onFollow: (e) => {
                    e.preventDefault();
                    router.push('/business-selection');
                },
            }}
            utilities={navButtons}
            i18nStrings={{
                searchIconAriaLabel: 'Search',
                searchDismissIconAriaLabel: 'Close search',
                overflowMenuTriggerText: 'More',
                overflowMenuTitleText: 'All',
                overflowMenuBackIconAriaLabel: 'Back',
                overflowMenuDismissIconAriaLabel: 'Close menu',
            }}
        ></TopNavigation>
    );
}
