import React, {useCallback, useRef} from 'react';
import {DropzoneOptions, FileRejection, useDropzone} from 'react-dropzone';
import axios from 'axios';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {F3ExcelsiorForecastStoreLambda} from '@amzn/f3-excelsior-forecast-store-lambda';
import {Link} from '@amzn/awsui-components-react';
import {Notification} from '../navigation/page-layout';

export interface TemplateDropzoneProps {
    templateType: string;
    useBulkOverrideV2?: boolean;
    // max number of polling status attempts
    maxPollAttempt?: number;
    delayBetweenAttemptInMs?: number;
    pushNotification: (notification: Notification) => void;
}

export function TemplateDropzone(props: TemplateDropzoneProps) {
    const {onDrop} = useTemplateDropzone(props);
    const {getRootProps, getInputProps} = useDropzone({
        onDrop: onDrop,
        // Maximum accepted number of files
        maxFiles: 1,
        accept: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    } as DropzoneOptions);

    return (
        <div className={'dropzone-div'} {...getRootProps()}>
            <input className={'dropzone'} {...getInputProps()} />
            <p>Drag and drop some files here, or click to select files.</p>
        </div>
    );
}

const formatErrors = (errors: string[]) => {
    return errors
        .map((error: string) => (error.endsWith('.') ? error : `${error}.`))
        .map((error: string, index: number) => (
            <div data-testid={index} key={index}>
                {`${index + 1}) ${error}`}
            </div>
        ));
};

// Visible for testing
export function useTemplateDropzone(props: TemplateDropzoneProps, forecastStoreClient?: F3ExcelsiorForecastStoreLambda) {
    const auth = useAuth();

    const statusByUploadId = useRef<Record<string, string>>({});
    const overrideValidationErrorsByUploadId = useRef<Record<string, string[]>>({});

    const BULK_OVERRIDE_VALUE_VALIDATIONS = 'bulkOverrideValueValidations';

    const forecastStoreViewClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreView);
    const forecastStoreEditClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreEdit);

    const forecastStoreViewClient = forecastStoreClient || new F3ExcelsiorForecastStoreLambda(forecastStoreViewClientConfiguration);
    const forecastStoreEditClient = forecastStoreClient || new F3ExcelsiorForecastStoreLambda(forecastStoreEditClientConfiguration);

    const uploadFileToS3 = useCallback(
        (url: string, file: File) => {
            return new Promise<boolean>((resolve) => {
                const fileUploadResult = axios.put(url, file, {
                    headers: {
                        'Content-Type': file.type,
                        'Access-Control-Allow-Origin': '*',
                        'x-amz-server-side-encryption': 'aws:kms',
                    },
                });

                fileUploadResult
                    .then(() => {
                        resolve(true);
                    })
                    .catch(() => {
                        props.pushNotification({
                            type: 'error',
                            content: <React.Fragment>File upload to S3 failed. Please try again.</React.Fragment>,
                        });
                        resolve(false);
                    });
            });
        },
        [props]
    );

    // Method for what to do on drop or select!
    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (fileRejections.length > 0) {
                props.pushNotification({
                    type: 'error',
                    content: (
                        <React.Fragment>The file was rejected due to format. Files need to be in format: .xlsx.</React.Fragment>
                    ),
                });
                return;
            }

            acceptedFiles.forEach(async (file: File) => {
                props.pushNotification({
                    type: 'info',
                    content: (
                        <React.Fragment>
                            <div>
                                Your file with file name <b>{file.name}</b> has been queued for processing.
                            </div>
                            <Link href="/history" variant="primary" color="inverted">
                                Go to the forecast history page to view the upload status.
                            </Link>
                        </React.Fragment>
                    ),
                });

                try {
                    const getLatestForecastIdsMetadataResponse = await forecastStoreViewClient
                        .getLatestForecastIdsMetadata({
                            businessId: auth.authInformation!.current!.businessId,
                            country: auth.authInformation!.current!.country,
                            flow: auth.authInformation!.current!.flow,
                        })
                        .promise();

                    if (getLatestForecastIdsMetadataResponse.latestForecastIdsMetadata.length != 1) {
                        props.pushNotification({
                            type: 'error',
                            content: (
                                <React.Fragment>
                                    There is no forecast to override for file with name <b>{file.name}</b>.
                                </React.Fragment>
                            ),
                        });
                        return;
                    }

                    const forecastRecord = getLatestForecastIdsMetadataResponse.latestForecastIdsMetadata[0];
                    const forecastId = forecastRecord.forecastId as string;
                    let uploadMetadata: {uploadId: string; uploadUrl: string};
                    if (props.useBulkOverrideV2) {
                        uploadMetadata = await forecastStoreEditClient
                            .uploadBulkOverrideTemplate({
                                businessId: auth.authInformation!.current!.businessId,
                                country: auth.authInformation!.current!.country,
                                flow: auth.authInformation!.current!.flow,
                                templateId: props.templateType,
                                uploadedFileName: file.name,
                                forecastId,
                            })
                            .promise();
                    } else {
                        uploadMetadata = await forecastStoreEditClient
                            .prepareForecastAssetUpload({
                                businessId: auth.authInformation!.current!.businessId,
                                country: auth.authInformation!.current!.country,
                                flow: auth.authInformation!.current!.flow,
                                templateType: props.templateType,
                                fileName: file.name,
                            })
                            .promise();
                    }

                    if (!(await uploadFileToS3(uploadMetadata.uploadUrl, file))) {
                        return;
                    }

                    let pollingAttempt = 0;

                    const delay = props.delayBetweenAttemptInMs || 1000;
                    const maxPollAttempt = props.maxPollAttempt || 900;

                    statusByUploadId.current[uploadMetadata.uploadId] = 'prepare_upload';
                    overrideValidationErrorsByUploadId.current[uploadMetadata.uploadId] = [];

                    const interval = setInterval(async () => {
                        if (pollingAttempt >= maxPollAttempt) {
                            props.pushNotification({
                                type: 'error',
                                content: <React.Fragment>Upload has timed out. Please try again.</React.Fragment>,
                            });
                            clearInterval(interval);
                            return;
                        }

                        try {
                            const getForecastAssetUploadStatusResponse = await forecastStoreViewClient
                                .getForecastAssetUploadStatus({
                                    uploadId: uploadMetadata.uploadId,
                                })
                                .promise();

                            const statusHasChanged =
                                statusByUploadId.current[uploadMetadata.uploadId] !== getForecastAssetUploadStatusResponse.status;
                            statusByUploadId.current[uploadMetadata.uploadId] = getForecastAssetUploadStatusResponse.status;

                            const overrideValueValidationsChanged =
                                overrideValidationErrorsByUploadId.current[uploadMetadata.uploadId].length !==
                                (
                                    getForecastAssetUploadStatusResponse.bulkOverrideValidationErrors?.[
                                        BULK_OVERRIDE_VALUE_VALIDATIONS
                                    ]?.errors ?? []
                                ).length;
                            overrideValidationErrorsByUploadId.current[uploadMetadata.uploadId] =
                                getForecastAssetUploadStatusResponse.bulkOverrideValidationErrors?.[BULK_OVERRIDE_VALUE_VALIDATIONS]
                                    ?.errors ?? [];

                            if (getForecastAssetUploadStatusResponse.status === 'succeeded') {
                                props.pushNotification({
                                    type: 'success',
                                    content: (
                                        <React.Fragment>
                                            Your file with file name <b>{file.name}</b> has been successfully uploaded.
                                        </React.Fragment>
                                    ),
                                });
                                clearInterval(interval);
                                return;
                            } else if (
                                getForecastAssetUploadStatusResponse.bulkOverrideValidationErrors?.[BULK_OVERRIDE_VALUE_VALIDATIONS]
                                    ?.errors &&
                                overrideValueValidationsChanged
                            ) {
                                const errorList =
                                    getForecastAssetUploadStatusResponse.bulkOverrideValidationErrors[
                                        BULK_OVERRIDE_VALUE_VALIDATIONS
                                    ].errors;

                                if (errorList) {
                                    props.pushNotification({
                                        type: 'warning',
                                        content: <React.Fragment>{formatErrors(errorList)}</React.Fragment>,
                                        header: `File ${
                                            file.name
                                        } has bulk override value validation warnings, but is still being applied to the forecast.${
                                            getForecastAssetUploadStatusResponse.bulkOverrideValidationErrors[
                                                BULK_OVERRIDE_VALUE_VALIDATIONS
                                            ].errorsTruncated
                                                ? ' There are more errors than being shown.'
                                                : ''
                                        }`,
                                    });
                                }
                            } else if (getForecastAssetUploadStatusResponse.status === 'in_progress' && statusHasChanged) {
                                props.pushNotification({
                                    type: 'info',
                                    content: (
                                        <React.Fragment>
                                            <div>
                                                Your file with file name <b>{file.name}</b> is now being processed.
                                            </div>
                                            <Link href="/history" variant="primary" color="inverted">
                                                Go to the forecast history page to view the upload status.
                                            </Link>
                                        </React.Fragment>
                                    ),
                                });
                            } else if (getForecastAssetUploadStatusResponse.status === 'failed') {
                                const errorList = getForecastAssetUploadStatusResponse.fileValidationErrors;

                                props.pushNotification({
                                    type: 'error',
                                    content: errorList ? (
                                        <React.Fragment>{formatErrors(errorList)}</React.Fragment>
                                    ) : (
                                        <React.Fragment>{`File ${file.name} could not upload due to a service error. Please try again.`}</React.Fragment>
                                    ),
                                    header: errorList
                                        ? `File ${file.name} has file validation errors.${
                                              getForecastAssetUploadStatusResponse.isExcessiveValidationErrors
                                                  ? ' There are more errors than being shown.'
                                                  : ''
                                          }`
                                        : undefined,
                                });
                                clearInterval(interval);
                                return;
                            }

                            pollingAttempt++;
                        } catch (error) {
                            clearInterval(interval);
                            props.pushNotification({
                                type: 'error',
                                content: (
                                    <React.Fragment>
                                        There is a service error. Please contact someone from the support team.
                                    </React.Fragment>
                                ),
                            });
                        }
                    }, delay);
                } catch (error) {
                    props.pushNotification({
                        type: 'error',
                        content: (
                            <React.Fragment>There is a service error. Please contact someone from the support team.</React.Fragment>
                        ),
                    });
                }
            });
            return;
        },
        [auth, props]
    );

    return {onDrop};
}
