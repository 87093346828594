import React from 'react';
import ReactDOM from 'react-dom';
import AppV2 from './components/app-v2';
import {BrowserRouter} from 'react-router-dom';
import {ProvideAuth} from './components/hooks/use-auth';
import {FatalErrorPage} from './components/navigation/fatal-error-page';
import '@amzn/awsui-global-styles/polaris.css';

document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(
        <BrowserRouter>
            <FatalErrorPage>
                <ProvideAuth>
                    <AppV2 />
                </ProvideAuth>
            </FatalErrorPage>
        </BrowserRouter>,
        document.getElementById('root')
    );
});
