import React, {useState} from 'react';
import {Notification} from '../navigation/page-layout';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {F3ExcelsiorForecastStoreLambda} from '@amzn/f3-excelsior-forecast-store-lambda';
import {translateErrorToReactNode} from '../common';
import {
    useDownloadOriginalUploadArtifact,
    useGetForecastAssetUploadStatus,
    useGetAllForecastHistoryOfTimeRangeOrLatest,
    ForecastAuditHistoryWithCreatedTime,
} from '../hooks/use-forecast-store-api';
import {Box, Button, ButtonDropdown, Header, Modal, SpaceBetween, StatusIndicator, Table} from '@amzn/awsui-components-react';
import Timestamp from '../common/timestamp';
import '../../assets/styles/orchestrator-styles.scss';
import PreviewTemplateButton from './preview-template-button';

const DAYS_TO_INSPECT = 28; // 4 weeks

const extractUploadErrorMessage = (response?: F3ExcelsiorForecastStoreLambda.Types.GetForecastAssetUploadStatusResponse) => {
    if (response?.fileValidationErrors) {
        return response.fileValidationErrors.map((error, index) => (
            <div data-testid={index} key={index}>
                {index + 1}
                {')'} {error}
            </div>
        ));
    } else if (response?.serviceError) {
        return <React.Fragment>{response.serviceError}</React.Fragment>;
    } else if (response?.bulkOverrideValidationErrors) {
        return response.bulkOverrideValidationErrors['bulkOverrideValueValidations'].errors.map((error, index) => (
            <div data-testid={index} key={index}>
                {index + 1}
                {')'} {error}
            </div>
        ));
    }
};

export default function ViewForecastHistory(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const [visible, setVisible] = useState(false);

    const forecastStoreViewClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreView);

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const currentTime = new Date();
    const startTime = new Date(new Date(currentTime).setDate(currentTime.getDate() - DAYS_TO_INSPECT));

    const {
        status: getAllForecastHistoryOfTimeRangeStatus,
        value: getAllForecastHistoryOfTimeRangeResponse,
        execute: getAllForecastHistoryOfTimeRange,
    } = useGetAllForecastHistoryOfTimeRangeOrLatest(
        forecastStoreViewClientConfiguration,
        {
            businessId: auth.authInformation!.current!.businessId,
            country: auth.authInformation!.current!.country,
            flow: auth.authInformation!.current!.flow,
            startTime: startTime.valueOf().toString(),
            endTime: currentTime.valueOf().toString(),
        },
        true,
        createErrorListener('GetAllForecastHistoryOfTimeRangeOrLatest failed'),
        [auth]
    );
    const [pageIndex, setPageIndex] = useState(0);
    const forecastHistory: Array<ForecastAuditHistoryWithCreatedTime> = getAllForecastHistoryOfTimeRangeResponse ?? [];

    const {value: getForecastAssetUploadStatusResponse, execute: executeGetForecastAssetUploadStatus} =
        useGetForecastAssetUploadStatus(
            forecastStoreViewClientConfiguration,
            createErrorListener('GetForecastAssetStatus failed'),
            [auth]
        );

    const {execute: executeDownloadOriginalUploadArtifact} = useDownloadOriginalUploadArtifact(
        forecastStoreViewClientConfiguration,
        createErrorListener('GetOriginalUploadDownloadUrl failed'),
        [auth]
    );

    const isLoading = getAllForecastHistoryOfTimeRangeStatus === 'idle' || getAllForecastHistoryOfTimeRangeStatus === 'pending';
    const audits = forecastHistory[pageIndex]?.audits || [];

    const columnDefinitions = [
        {
            id: 'startTime',
            header: 'Start Time',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => <Timestamp datetime={item.startTime} />,
            minWidth: '150px',
        },
        {
            id: 'eventName',
            header: 'Event Name',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => item.eventName,
            minWidth: '150px',
        },
        {
            id: 'templateName',
            header: 'Template Name',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => item.attachment?.templateName,
            minWidth: '150px',
        },
        {
            id: 'endTime',
            header: 'End Time',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => {
                if (item.endTime) {
                    return <Timestamp datetime={item.endTime} />;
                } else {
                    return '';
                }
            },
            minWidth: '150px',
        },
        {
            id: 'initiator',
            header: 'Initiator',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => item.initiator,
            minWidth: '100px',
        },
        {
            id: 'status',
            header: 'Status',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => {
                const indicatorType =
                    item.status === 'succeeded'
                        ? 'success'
                        : item.status === 'queued'
                        ? 'pending'
                        : item.status === 'in_progress'
                        ? 'in-progress'
                        : 'error';

                return (
                    <StatusIndicator data-testid="override-status" type={indicatorType}>
                        {item.status}
                    </StatusIndicator>
                );
            },
            minWidth: '100px',
        },
        {
            id: 'action',
            header: 'Action',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => {
                if (
                    ['BULK_OVERRIDE', 'MARKETING_EVENT', 'RESET_SELECTED_OVERRIDES', 'BASELINE_WEEK_OVERRIDE'].includes(
                        item.eventName
                    )
                ) {
                    return (
                        <>
                            <Button
                                data-testid="download-upload-artifact"
                                iconName="download"
                                variant="icon"
                                onClick={async () => {
                                    await executeDownloadOriginalUploadArtifact({
                                        forecastId: forecastHistory[pageIndex]?.forecastId || '',
                                        uploadId: item.attachment?.uploadId || '',
                                    });
                                }}
                            />
                            <PreviewTemplateButton
                                forecastId={forecastHistory[pageIndex]?.forecastId || ''}
                                uploadId={item.attachment?.uploadId || ''}
                                pushNotification={props.pushNotification}
                            />
                        </>
                    );
                }
                return;
            },
            minWidth: '100px',
        },
        {
            id: 'info',
            header: 'Info',
            cell: (item: F3ExcelsiorForecastStoreLambda.Types.ForecastAudit) => {
                if (
                    ['BULK_OVERRIDE', 'MARKETING_EVENT', 'RESET_SELECTED_OVERRIDES', 'BASELINE_WEEK_OVERRIDE'].includes(
                        item.eventName
                    ) &&
                    item.status === 'failed'
                ) {
                    return (
                        <Button
                            data-testid="open-upload-error-modal"
                            iconName="status-info"
                            variant="icon"
                            onClick={async () => {
                                await executeGetForecastAssetUploadStatus({
                                    uploadId: item.attachment?.uploadId || '',
                                });
                                setVisible(true);
                            }}
                        />
                    );
                }
                if (['BULK_OVERRIDE'].includes(item.eventName) && item.status === 'succeeded_with_warnings') {
                    return (
                        <Button
                            data-testid="open-upload-error-modal"
                            iconName="status-info"
                            variant="icon"
                            onClick={async () => {
                                await executeGetForecastAssetUploadStatus({
                                    uploadId: item.attachment?.uploadId || '',
                                });
                                setVisible(true);
                            }}
                        />
                    );
                }
                return;
            },
            minWidth: '100px',
        },
    ];

    return (
        <React.Fragment>
            <div className="main-header">
                <Header variant="h2">Forecast History</Header>
            </div>
            <div className="main-content">
                <Table
                    data-testid="history"
                    columnDefinitions={columnDefinitions}
                    items={audits}
                    loading={isLoading}
                    loadingText="Loading history"
                    header={
                        <Header
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        data-testid="refresh"
                                        iconName="refresh"
                                        variant="normal"
                                        onClick={async () => {
                                            setPageIndex(0);
                                            await getAllForecastHistoryOfTimeRange();
                                        }}
                                    />
                                    <ButtonDropdown
                                        data-testid="select-forecast"
                                        items={forecastHistory.map((forecast) => ({
                                            text: forecast.createdTime.toLocaleString(),
                                            id: forecast.forecastId,
                                        }))}
                                        onItemClick={async (item) => {
                                            const foundIndex = forecastHistory.findIndex(
                                                (forecast) => forecast.forecastId === item.detail.id
                                            );
                                            setPageIndex(foundIndex);
                                        }}
                                        loading={isLoading}
                                        disabled={isLoading}
                                    >
                                        Select Forecast
                                    </ButtonDropdown>
                                </SpaceBetween>
                            }
                        >
                            {(pageIndex == 0 ? 'Latest Forecast' : 'Forecast') +
                                ' ' +
                                (forecastHistory && forecastHistory.length > 0
                                    ? 'Generated on ' +
                                      forecastHistory[pageIndex].createdTime.toLocaleString('en-us', {
                                          weekday: 'short',
                                          year: 'numeric',
                                          month: 'short',
                                          day: 'numeric',
                                          timeZoneName: 'short',
                                      })
                                    : '')}
                        </Header>
                    }
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No forecast history</b>
                        </Box>
                    }
                />
                <Modal
                    data-testid="upload-error-modal"
                    onDismiss={() => setVisible(false)}
                    visible={visible}
                    closeAriaLabel="Close modal"
                    size="large"
                    header="File Errors"
                >
                    {extractUploadErrorMessage(getForecastAssetUploadStatusResponse)}
                </Modal>
            </div>
        </React.Fragment>
    );
}
