import React from 'react';
import {Notification} from '../navigation/page-layout';
import {useRouter} from '../hooks/use-router';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {translateErrorToReactNode} from '../common';
import {useGetForecastValidationErrors} from '../hooks/use-forecast-generator-api';
import {Header, Table} from '@amzn/awsui-components-react';
import PortalLoader from '../common/PortalLoader';
import startCase from 'lodash.startcase';

interface ViewForecastGenerationErrorsQuery {
    forecastGenerationId: string;
}

const errorTypeDisplayNames: {[key: string]: string} = {
    EMPTY_FORECAST: 'Empty Forecast',
    MISSING_DATES: 'Missing Dates',
    EXTRA_DATES: 'Extra Dates',
    MISSING_GRANULARITY_KEY: 'Missing Granularity Key',
    INVALID_GRANULARITY_RECORD: 'Missing Granularity Record',
    DUPLICATE_GRANULARITY_RECORD: 'Duplicate Granularity Record',
    DUPLICATE_SLOTS: 'Duplicate Slots',
    INVALID_START_DATE: 'Invalid Start Date',
};

export default function ViewForecastGenerationErrors(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const router = useRouter();
    const params = router.query as ViewForecastGenerationErrorsQuery;

    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastGeneratorView);

    const onErrorListener = (e: any) => {
        props.pushNotification({
            type: 'error',
            content: translateErrorToReactNode(e),
            header: 'GetForecastValidationErrors failed',
        });
    };

    const {status, value} = useGetForecastValidationErrors(
        clientConfiguration,
        {
            forecastGenerationId: params.forecastGenerationId,
        },
        true,
        onErrorListener,
        [auth, params]
    );

    let content = <React.Fragment />;
    if (status === 'idle' || status === 'pending') {
        content = (
            <div className="main-content">
                <Header variant="h2">Loading Validation Errors</Header>
                <PortalLoader />
            </div>
        );
    } else if (status === 'success') {
        const validationErrors = value?.validationErrors || [];
        if (validationErrors.length == 0) {
            content = (
                <div className="main-content">
                    <Header variant="h2">
                        No validation errors found in database. Please check CloudWatch logs for failure details.
                    </Header>
                </div>
            );
        } else {
            const records = validationErrors.map((e) => {
                const granularityRecord = JSON.parse(e.granularityRecord) as Record<string, string>;
                return {
                    ...granularityRecord,
                    errorType: e.errorType,
                    errorMessage: e.errorMessage,
                };
            });

            const granularityKeys = Array.from(
                new Set(records.flatMap((r) => Object.keys(r)).filter((k) => k != 'errorType' && k != 'errorMessage'))
            );

            // To give a deterministic ordering on every render
            granularityKeys.sort();

            const granularityRecordColumnDefinitions = granularityKeys.map((k) => ({
                id: k,
                header: startCase(k),
                cell: (e: Record<string, string>) => e[k] || '',
            }));

            const errorColumnDefinitions = [
                {
                    id: 'errorType',
                    header: 'Error',
                    cell: (e: Record<string, string>) => errorTypeDisplayNames[e.errorType] || e.errorType,
                },
                {
                    id: 'errorMessage',
                    header: 'Details',
                    cell: (e: Record<string, string>) => e.errorMessage,
                },
            ];

            const columnDefinitions = granularityRecordColumnDefinitions.concat(errorColumnDefinitions);

            content = (
                <div className="main-content">
                    <Header variant="h2">Validation Errors for {value?.createdTime}</Header>
                    <Table data-testid="validation-errors" columnDefinitions={columnDefinitions} items={records} />
                </div>
            );
        }
    }

    return (
        <React.Fragment>
            <div className="main-header">
                <Header variant="h1">Forecast Generation ID: {params.forecastGenerationId}</Header>
            </div>
            {content}
        </React.Fragment>
    );
}
