import Analytics from '@aws-amplify/analytics';
import {PortalEventName} from './portal-event-name-enum';

function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export interface PortalEvent {
    eventName: PortalEventName;
    eventData: any;
}

export function recordUserEvent(event: PortalEvent) {
    const date = new Date();
    const eventDate = formatDate(date);
    const data = {
        ...event,
        ...{eventDateTime: new Date(date.toUTCString()).toISOString(), eventDate: eventDate},
    };
    try {
        Analytics.record(
            {
                data: data,
                partitionKey: eventDate,
                streamName: 'UxPortalWebAnalyticsStream', // TODO: probably also want this to come from settings.json, although the name is the same across all environments
            },
            'AWSKinesisFirehose'
        );
    } catch (e) {
        // don't re-throw error as we don't want the portal to crash when analytics logging fails
    }
}
