/**
 * Some businesses have asked that the display value for 'flow' be changed.
 */
// NOTE: The following two functions are temporary until we can complete the following SIM
// https://issues.amazon.com/issues/GSC-Forecasting-1984
export function getFlowDisplayNamesByBusinessAndFlow(businessId: string, flow: string): string {
    const flowToDisplayFlowByBusinessIdMap: {[key: string]: string} = {
        kaspianOutbound: 'Sales',
    };
    const businessAndFlow = businessId.concat(flow);

    if (flowToDisplayFlowByBusinessIdMap[businessAndFlow]) {
        return flowToDisplayFlowByBusinessIdMap[businessAndFlow];
    }
    return flow;
}

export function getFlowByBusinessAndFlowDisplayName(businessId: string, flow: string): string {
    const flowDisplayNameToFlowByBusinessIdMap: {[key: string]: string} = {
        kaspianSales: 'Outbound',
    };
    const businessAndFlow = businessId.concat(flow);

    if (flowDisplayNameToFlowByBusinessIdMap[businessAndFlow]) {
        return flowDisplayNameToFlowByBusinessIdMap[businessAndFlow];
    }
    return flow;
}
