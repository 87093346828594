import React, {useEffect, useRef} from 'react';
import {Notification} from '../navigation/page-layout';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {translateErrorToReactNode} from '../common';
import {useGetAccuracyDashboardUrl} from '../hooks/use-forecast-store-api';
import PortalLoader from '../common/PortalLoader';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import '../../assets/styles/forecast-styles.scss';
import {useGetTenantConfigurationAsync} from '../hooks/use-configuration-api';

export function ViewAccuracyDashboard(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();

    const forecastStoreInsightClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(
        ServiceResource.ForecastStoreInsights
    );
    const configurationViewClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ConfigurationView);

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const {status: getDashboardUrlStatus, value: getAccuracyDashboardUrlResponse} = useGetAccuracyDashboardUrl(
        forecastStoreInsightClientConfiguration,
        {
            businessId: auth.authInformation!.current!.businessId,
            country: auth.authInformation!.current!.country,
            flow: auth.authInformation!.current!.flow,
        },
        true,
        createErrorListener('GetDashboardUrl failed'),
        [auth]
    );

    const {status: getConfigurationStatus, value: getConfigurationResponse} = useGetTenantConfigurationAsync(
        configurationViewClientConfiguration,
        {
            business: auth.authInformation!.current!.businessId,
            country: auth.authInformation!.current!.country,
            flow: auth.authInformation!.current!.flow,
            groupId: 'forecast',
        },
        true,
        createErrorListener('GetDashboardUrl failed'),
        [auth]
    );

    const isLoading =
        getDashboardUrlStatus === 'idle' ||
        getDashboardUrlStatus === 'pending' ||
        getConfigurationStatus === 'idle' ||
        getConfigurationStatus === 'pending';

    if (isLoading) {
        return <PortalLoader />;
    }

    return (
        <React.Fragment>
            <ForecastSnapshotAccuracyDashboard
                url={getAccuracyDashboardUrlResponse?.dashboardUrl || ''}
                business={auth.authInformation!.current!.businessId}
                country={auth.authInformation!.current!.country}
                flow={auth.authInformation!.current!.flow}
                confidenceLevel={
                    JSON.parse(getConfigurationResponse?.configuration || '{}')?.forecastGranularityValues?.confidenceLevel?.[0] ??
                    'NA'
                }
                pushNotification={props.pushNotification}
            />
        </React.Fragment>
    );
}

interface ForecastSnapshotAccuracyDashboardProps {
    url: string;
    business: string;
    country: string;
    flow: string;
    confidenceLevel: string;
    pushNotification: (notification: Notification) => void;
}

function ForecastSnapshotAccuracyDashboard(props: ForecastSnapshotAccuracyDashboardProps) {
    const ref = useRef<HTMLDivElement | null>(null);

    function onError(error: any) {
        props.pushNotification({
            type: 'error',
            header: 'Embeddeding Quicksight Accuracy Dashboard failed',
            content: translateErrorToReactNode(error),
        });
    }

    useEffect(() => {
        const dashboard = QuickSightEmbedding.embedDashboard({
            url: props.url,
            // ref.current is definitely not null in useEffect
            container: ref.current || '',
            locale: 'en-US',
            footerPaddingEnabled: true,
            parameters: {
                business: props.business,
                country: props.country,
                flow: props.flow,
                confidenceLevel: props.confidenceLevel,
            },
        });
        dashboard.on('error', onError);
    }, [props.business, props.country, props.flow]);

    return <div id={'embeddingContainer'} ref={ref} />;
}
