import {F3ExcelsiorForecastStoreLambda} from '@amzn/f3-excelsior-forecast-store-lambda';
import {useAsync, useExecute} from './use-async';
import {openInNewTab, wrapOnErrorListener} from '../common';
import {DependencyList, useCallback} from 'react';
import {
    BulkOverrideTemplates,
    ForecastAuditList,
    ForecastIdMetaData,
} from '@amzn/f3-excelsior-forecast-store-lambda/clients/f3excelsiorforecaststorelambda';
import {read, WorkBook} from 'xlsx';

export interface ForecastAuditHistory {
    forecastId: string;
    audits: ForecastAuditList;
}

export interface ForecastAuditHistoryWithCreatedTime {
    forecastId: string;
    createdTime: Date;
    audits: ForecastAuditList;
}

export function convertUTCTimestampToDate(utcTimestamp: string) {
    const date = new Date(utcTimestamp);
    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    date.setHours(hours - offset);

    return date;
}

/* eslint-disable react-hooks/exhaustive-deps */
export function useGetDashboardUrl(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: F3ExcelsiorForecastStoreLambda.Types.GetDashboardUrlRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.getDashboardUrl(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function useGetAccuracyDashboardUrl(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: F3ExcelsiorForecastStoreLambda.Types.GetDashboardUrlRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.getWapeDashboardUrl(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function useGetHistory(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: {
        businessId: string;
        country: string;
        flow: string;
    },
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync<ForecastAuditHistory>(
        useCallback(async () => {
            const getForecastId = wrapOnErrorListener(client.getLatestForecastIdsMetadata(request).promise(), onErrorListener);
            const getForecastIdResponse = await getForecastId;
            const result =
                getForecastIdResponse && getForecastIdResponse.latestForecastIdsMetadata.length > 0
                    ? client
                          .getForecastTuningHistory({
                              forecastId: getForecastIdResponse!.latestForecastIdsMetadata[0].forecastId,
                          })
                          .promise()
                          .then((response) => ({
                              forecastId: getForecastIdResponse.latestForecastIdsMetadata[0].forecastId,
                              ...response,
                          }))
                    : Promise.reject(
                          `No forecastId found for businessId: ${request.businessId}, country: ${request.country}, flow: ${request.flow}`
                      );
            return await wrapOnErrorListener(result, onErrorListener);
        }, deps),
        immediate
    );
}

export function useGetAllForecastHistoryOfTimeRangeOrLatest(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: {
        businessId: string;
        country: string;
        flow: string;
        startTime: string;
        endTime: string;
    },
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync<Array<ForecastAuditHistoryWithCreatedTime>>(
        useCallback(async () => {
            const listForecastIdsMetadataResponse = await wrapOnErrorListener(
                client.listForecastIdsMetadata(request).promise(),
                onErrorListener
            );

            let forecastIdsMetadata: ForecastIdMetaData[] = [];

            if (listForecastIdsMetadataResponse && listForecastIdsMetadataResponse.forecastIdsMetadata.length > 0) {
                forecastIdsMetadata = listForecastIdsMetadataResponse.forecastIdsMetadata;
            } else {
                const getLatestForecastIdResponse = await wrapOnErrorListener(
                    client
                        .getLatestForecastIdsMetadata({
                            businessId: request.businessId,
                            country: request.country,
                            flow: request.flow,
                        })
                        .promise(),
                    onErrorListener
                );
                if (getLatestForecastIdResponse && getLatestForecastIdResponse.latestForecastIdsMetadata.length > 0) {
                    forecastIdsMetadata = getLatestForecastIdResponse.latestForecastIdsMetadata;
                }
            }

            if (forecastIdsMetadata.length === 0) {
                return await wrapOnErrorListener(
                    Promise.reject(
                        `No forecastId found for businessId: ${request.businessId}, country: ${request.country}, flow: ${request.flow}`
                    ),
                    onErrorListener
                );
            }

            const result = new Array<ForecastAuditHistoryWithCreatedTime>();

            for (let i = 0; i < forecastIdsMetadata.length; i++) {
                const forecastId = forecastIdsMetadata[i].forecastId;
                const createdTime = forecastIdsMetadata[i].createdTime;
                const forecastTuningHistory = await wrapOnErrorListener(
                    client
                        .getForecastTuningHistory({forecastId: forecastId})
                        .promise()
                        .then((response) => {
                            return {
                                forecastId: forecastId,
                                createdTime: convertUTCTimestampToDate(createdTime),
                                ...response,
                            };
                        }),
                    onErrorListener
                );
                result.push(forecastTuningHistory);
            }
            return result;
        }, deps),
        immediate
    );
}

export function useGetForecastDescription(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: F3ExcelsiorForecastStoreLambda.Types.GetLatestForecastIdsMetadataRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.getLatestForecastIdsMetadata(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function useGetForecastAssetUploadStatus(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<
        F3ExcelsiorForecastStoreLambda.Types.GetForecastAssetUploadStatusResponse,
        (
            r: F3ExcelsiorForecastStoreLambda.Types.GetForecastAssetUploadStatusRequest
        ) => Promise<F3ExcelsiorForecastStoreLambda.Types.GetForecastAssetUploadStatusResponse>
    >(
        useCallback(
            (request: F3ExcelsiorForecastStoreLambda.Types.GetForecastAssetUploadStatusRequest) =>
                wrapOnErrorListener(client.getForecastAssetUploadStatus(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useDownloadOriginalUploadArtifact(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<
        F3ExcelsiorForecastStoreLambda.Types.GetOriginalUploadDownloadUrlResponse,
        (
            r: F3ExcelsiorForecastStoreLambda.Types.GetOriginalUploadDownloadUrlRequest
        ) => Promise<F3ExcelsiorForecastStoreLambda.Types.GetOriginalUploadDownloadUrlResponse>
    >(
        useCallback(async (request: F3ExcelsiorForecastStoreLambda.Types.GetOriginalUploadDownloadUrlRequest) => {
            const getDowloadUrl = await wrapOnErrorListener(
                client.getOriginalUploadDownloadUrl(request).promise(),
                onErrorListener
            );
            openInNewTab(getDowloadUrl.downloadUrl);
            return getDowloadUrl;
        }, deps)
    );
}

export function useGetOriginalUploadArtifact(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<WorkBook, (r: F3ExcelsiorForecastStoreLambda.Types.GetOriginalUploadDownloadUrlRequest) => Promise<WorkBook>>(
        useCallback(async (request: F3ExcelsiorForecastStoreLambda.Types.GetOriginalUploadDownloadUrlRequest) => {
            const getDowloadUrl = await wrapOnErrorListener(
                client.getOriginalUploadDownloadUrl(request).promise(),
                onErrorListener
            );
            const data = await (await fetch(getDowloadUrl.downloadUrl)).arrayBuffer();
            return read(data, {cellDates: true}); // parse datatime as Date
        }, deps)
    );
}

export function useGetAggregateCalculationStatus(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: F3ExcelsiorForecastStoreLambda.Types.GetAggregateCalculationStatusRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.getAggregateCalculationStatus(request).promise(), onErrorListener), deps),
        immediate
    );
}

/**
 * A bit confusing to why it is called V1 and the underlying API is called getTemplateDownloadUrl"V3".
 * In v1 and v2 is in comparison to Bulk Override Automation APIs.
 *
 * V1 = the API before Bulk Override Automation API, whose current version is V3
 * V2 = the Bulk Override Automation API
 */
export function useDownloadTemplateV1(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: F3ExcelsiorForecastStoreLambda.Types.GetTemplateDownloadUrlRequestV3,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(
            () =>
                wrapOnErrorListener(
                    client
                        .getTemplateDownloadUrlV3(request)
                        .promise()
                        .then((response) => {
                            openInNewTab(response.downloadUrl);
                            return response;
                        }),
                    onErrorListener
                ),
            deps
        ),
        false
    );
}

export function useDownloadTemplateV2(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: F3ExcelsiorForecastStoreLambda.Types.DownloadBulkOverrideTemplateRequest,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(
            () =>
                wrapOnErrorListener(
                    client
                        .downloadBulkOverrideTemplate(request)
                        .promise()
                        .then((response) => {
                            openInNewTab(response.downloadUrl);
                            return response;
                        }),
                    onErrorListener
                ),
            deps
        ),
        false
    );
}

export function useResetOverrides(
    forecastStoreViewClientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    forecastStoreEditClientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: {
        businessId: string;
        country: string;
        flow: string;
    },
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    forecastStoreViewClient = new F3ExcelsiorForecastStoreLambda(forecastStoreViewClientConfiguration),
    forecastStoreEditClient = new F3ExcelsiorForecastStoreLambda(forecastStoreEditClientConfiguration)
) {
    return useAsync(
        useCallback(async () => {
            const getForecastId = wrapOnErrorListener(
                forecastStoreViewClient.getLatestForecastIdsMetadata(request).promise(),
                onErrorListener
            );
            const getForecastIdResponse = await getForecastId;
            const result =
                getForecastIdResponse && getForecastIdResponse.latestForecastIdsMetadata.length > 0
                    ? forecastStoreEditClient
                          .resetOverrides({
                              ...request,
                              forecastId: getForecastIdResponse!.latestForecastIdsMetadata[0].forecastId,
                          })
                          .promise()
                    : Promise.resolve({});
            return await wrapOnErrorListener(result, onErrorListener);
        }, deps),
        false
    );
}

export function useApplyOverridesToTemplate(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<
        F3ExcelsiorForecastStoreLambda.Types.PopulateBulkOverrideTemplateOutput,
        (
            r: F3ExcelsiorForecastStoreLambda.Types.PopulateBulkOverrideTemplateInput
        ) => Promise<F3ExcelsiorForecastStoreLambda.Types.PopulateBulkOverrideTemplateOutput>
    >(
        useCallback(
            (request: F3ExcelsiorForecastStoreLambda.Types.PopulateBulkOverrideTemplateInput) =>
                wrapOnErrorListener(client.populateBulkOverrideTemplate(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useListBulkOverrideTemplate(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: F3ExcelsiorForecastStoreLambda.Types.ListBulkOverrideTemplateRequest,
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.listBulkOverrideTemplate(request).promise(), onErrorListener), deps),
        immediate
    );
}

export function useListBulkOverrideTemplateForTags(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: {
        businessId: string;
        country: string;
        flow: string;
        bulkOverrideTemplateTags: string[];
    },
    immediate: boolean,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(async () => {
            const result: BulkOverrideTemplates = [];
            for (const bulkOverrideTemplateTag of request.bulkOverrideTemplateTags) {
                const response = await wrapOnErrorListener(
                    client
                        .listBulkOverrideTemplate({
                            businessId: request.businessId,
                            country: request.country,
                            flow: request.flow,
                            tag: bulkOverrideTemplateTag,
                        })
                        .promise(),
                    onErrorListener
                );
                result.push(...response.templates);
            }
            return result;
        }, deps),
        immediate
    );
}

export function usePublishForecast(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<
        F3ExcelsiorForecastStoreLambda.Types.PromotePrefinalAndPublishForecastResponse,
        (
            r: F3ExcelsiorForecastStoreLambda.Types.PromotePrefinalAndPublishForecastRequest
        ) => Promise<F3ExcelsiorForecastStoreLambda.Types.PromotePrefinalAndPublishForecastResponse>
    >(
        useCallback(
            (request: F3ExcelsiorForecastStoreLambda.Types.PromotePrefinalAndPublishForecastRequest) =>
                wrapOnErrorListener(client.promotePrefinalAndPublishForecast(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useIngestConstraints(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<
        F3ExcelsiorForecastStoreLambda.Types.TriggerIngestionResponse,
        (
            r: F3ExcelsiorForecastStoreLambda.Types.TriggerIngestionRequest
        ) => Promise<F3ExcelsiorForecastStoreLambda.Types.TriggerIngestionResponse>
    >(
        useCallback(
            (request: F3ExcelsiorForecastStoreLambda.Types.TriggerIngestionRequest) =>
                wrapOnErrorListener(client.triggerIngestion(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useGetEmailIdentity(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.getEmailIdentity().promise(), onErrorListener), deps),
        true
    );
}

export function useAddEmailIdentity(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<unknown, () => Promise<unknown>>(
        useCallback(() => wrapOnErrorListener(client.addEmailIdentity().promise(), onErrorListener), deps)
    );
}

export function useDeleteEmailIdentity(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<unknown, () => Promise<unknown>>(
        useCallback(() => wrapOnErrorListener(client.deleteEmailIdentity().promise(), onErrorListener), deps)
    );
}

export function usePrepareInsights(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<
        F3ExcelsiorForecastStoreLambda.Types.PrepareInsightsResponse,
        (
            r: F3ExcelsiorForecastStoreLambda.Types.PrepareInsightsRequest
        ) => Promise<F3ExcelsiorForecastStoreLambda.Types.PrepareInsightsResponse>
    >(
        useCallback(
            (request: F3ExcelsiorForecastStoreLambda.Types.PrepareInsightsRequest) =>
                wrapOnErrorListener(client.prepareInsights(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useGetInsights(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<
        F3ExcelsiorForecastStoreLambda.Types.GetInsightsResponse,
        (
            r: F3ExcelsiorForecastStoreLambda.Types.GetInsightsRequest
        ) => Promise<F3ExcelsiorForecastStoreLambda.Types.GetInsightsResponse>
    >(
        useCallback(
            (request: F3ExcelsiorForecastStoreLambda.Types.GetInsightsRequest) =>
                wrapOnErrorListener(client.getInsights(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useGetForecastLocations(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    request: F3ExcelsiorForecastStoreLambda.Types.GetForecastLocationsRequest,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useAsync(
        useCallback(() => wrapOnErrorListener(client.getForecastLocations(request).promise(), onErrorListener), deps),
        true
    );
}

export function useUploadBaselineWeekOverrides(
    clientConfiguration: F3ExcelsiorForecastStoreLambda.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new F3ExcelsiorForecastStoreLambda(clientConfiguration)
) {
    return useExecute<
        unknown,
        (request: F3ExcelsiorForecastStoreLambda.Types.UploadBaselineWeekOverridesInput) => Promise<unknown>
    >(
        useCallback(
            (request: F3ExcelsiorForecastStoreLambda.Types.UploadBaselineWeekOverridesInput) =>
                wrapOnErrorListener(client.uploadBaselineWeekOverrides(request).promise(), onErrorListener),
            deps
        )
    );
}
/* eslint-enable react-hooks/exhaustive-deps */
