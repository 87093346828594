import React, {useState} from 'react';
import '../../assets/styles/insights-styles.scss';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {usePrepareInsights, useGetInsights, useGetForecastLocations} from '../hooks/use-forecast-store-api';
import {
    Button,
    DateRangePicker,
    Header,
    Select,
    SelectProps,
    SpaceBetween,
    Flashbar,
    TableProps,
    Table,
} from '@amzn/awsui-components-react';
import {translateErrorToReactNode} from '../common';
import {Notification} from '../navigation/page-layout';
import {InsightList} from '@amzn/f3-excelsior-forecast-store-lambda/clients/f3excelsiorforecaststorelambda';
import {getBusinessIdDisplayNames} from '../business-selection/business-selection';

interface InsightsProps {
    pushNotification: (notification: Notification) => void;
}

export default function Insights(props: InsightsProps) {
    const auth = useAuth();
    const forecastStoreInsightsClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(
        ServiceResource.ForecastStoreInsights
    );

    const [businessId, setBusinessId] = useState({
        label: getBusinessIdDisplayNames(auth.authInformation!.current!.businessId),
        value: auth.authInformation!.current!.businessId,
    } as SelectProps.Option | null);
    const businessIdOptions = Array.from(new Set((auth.authInformation?.businessContexts || []).map((v) => v.businessId))).map(
        (v) => ({label: getBusinessIdDisplayNames(v), value: v} as SelectProps.Option)
    );
    const [country, setCountry] = useState({
        label: auth.authInformation!.current!.country.toUpperCase(),
        value: auth.authInformation!.current!.country,
    } as SelectProps.Option | null);
    const [countryOptions, setCountryOptions] = useState(
        Array.from(
            new Set(
                (auth.authInformation?.businessContexts || [])
                    .filter((v) => !businessId?.value || v.businessId === businessId?.value)
                    .map((v) => v.country)
            )
        ).map((v) => ({label: v, value: v} as SelectProps.Option)) as SelectProps.Option[]
    );
    const [flow, setFlow] = useState({
        label: auth.authInformation!.current!.flow.toUpperCase(),
        value: auth.authInformation!.current!.flow,
    } as SelectProps.Option | null);
    const [flowOptions, setFlowOptions] = useState(
        Array.from(
            new Set(
                (auth.authInformation?.businessContexts || [])
                    .filter((bc) => bc.businessId === businessId?.value && bc.country === country?.value)
                    .map((v) => v.flow)
            )
        ).map((v) => ({label: getBusinessIdDisplayNames(v), value: v} as SelectProps.Option)) as SelectProps.Option[]
    );

    const [forecastHierarchy, setForecastHierarchy] = useState('');
    const [dateRangeValue, setDateRangeValue] = useState(undefined as any);
    const [statusMessage, setStatusMessage] = useState('');
    const [statusInfo, setStatusInfo] = useState('info' as 'success' | 'warning' | 'info' | 'error');
    const [buttonClicked, setButtonClicked] = useState(false);
    const [showTable, setShowTable] = useState(false);

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
            });
        };
    }

    const {execute: executePrepareInsights, value: prepareInsightsResponse} = usePrepareInsights(
        forecastStoreInsightsClientConfiguration,
        createErrorListener('PrepareInsights failed'),
        [auth]
    );

    const {execute: executeGetInsights, value: getInsightsResponse} = useGetInsights(
        forecastStoreInsightsClientConfiguration,
        createErrorListener('GetInsights failed'),
        [auth]
    );

    const {value: getForecastLocationsResponse} = useGetForecastLocations(
        forecastStoreInsightsClientConfiguration,
        {
            businessId: businessId?.value ?? '',
            country: country?.value ?? '',
            flow: flow?.value ?? '',
        },
        createErrorListener('GetForecastLocations failed'),
        [auth]
    );

    const prepareAndGetInsightsUntilFinish = async () => {
        const prepareResponse = await executePrepareInsights({
            businessId: businessId?.value ?? '',
            country: country?.value ?? '',
            flow: flow?.value ?? '',
            startTime: new Date(dateRangeValue.startDate).toISOString(),
            endTime: new Date(dateRangeValue.endDate).toISOString(),
            forecastHierarchy: forecastHierarchy.split('-'),
        });

        setStatusMessage('Insights Retrieval In Progress. (id: ' + prepareResponse.insightId + ')');
        setStatusInfo('info');
        setButtonClicked(true);

        let pollingAttempt = 1;
        const delay = 1000;
        const maxPollAttempt = 500;
        const interval = setInterval(async () => {
            const getResponse = await executeGetInsights({
                businessId: businessId?.value ?? '',
                country: country?.value ?? '',
                flow: flow?.value ?? '',
                insightId: prepareResponse.insightId,
            });
            const getStatus = getResponse.status;
            if (pollingAttempt >= maxPollAttempt || getStatus === 'success' || getStatus === 'error') {
                if (getStatus === 'success') {
                    setStatusMessage('Insights Successfully Retrieved! (id: ' + prepareResponse.insightId + ' )');
                    setStatusInfo('success');
                } else if (getStatus === 'error') {
                    setStatusMessage('Insights Retrieval Failed with Message: ' + getResponse.error);
                    setStatusInfo('error');
                } else {
                    setStatusMessage('Error: Insights Retrieval took too long.');
                    setStatusInfo('error');
                }
                clearInterval(interval);
                return;
            }
            pollingAttempt++;
        }, delay);
    };
    const insightSortedKeys: string[] = [
        'Raw Model',
        'Holiday Lift',
        'Model Final',
        'Baseline',
        'Override',
        'Prefinal',
        'Ratios',
        'Marketing Events',
        'Absolute Adjustment',
        'Percentage Adjustments',
        'Marketing Event Sum',
        'Constraint',
        'Constraint Type',
        'Constraint Percentage',
        'Capped Volume',
        'Rolled Capacity By Slot',
        'Constrained Forecast',
    ];

    const generateTableColumns = (insights: InsightList) => {
        if (insights.length === 0) return [];
        const tableColumns: TableProps.ColumnDefinition<Map<string, string>>[] = [];
        const insight = insights[0];
        tableColumns.push({
            id: 'forecastHierarchy',
            header: 'Forecast Hierarchy',
            cell: (e) => e.get('forecastHierarchy'),
            width: 200,
            minWidth: 20,
        });
        tableColumns.push({
            id: 'datetime',
            header: 'Date Time',
            cell: (e) => e.get('datetime'),
            width: 200,
            minWidth: 20,
        });
        for (const key of insightSortedKeys) {
            tableColumns.push({
                id: key,
                header: key,
                cell: (e) => e.get(key),
                width: 200,
                minWidth: 20,
            });
        }
        for (const key in insight.insightValues) {
            if (!insightSortedKeys.includes(key)) {
                tableColumns.push({
                    id: key,
                    header: key,
                    cell: (e) => e.get(key),
                    width: 200,
                    minWidth: 20,
                });
            }
        }
        return tableColumns;
    };
    const tableColumns = generateTableColumns(getInsightsResponse?.insights ? getInsightsResponse?.insights : []);

    const generateTableContent = (insights: InsightList) => {
        const tableContent: Map<string, string>[] = [];

        for (const insight of insights) {
            const insightValues = new Map<string, string>();
            insightValues.set('forecastHierarchy', insight.forecastHierarchy.toString());
            insightValues.set('datetime', insight.datetime);
            for (const key in insight.insightValues) {
                insightValues.set(key, insight.insightValues[key]);
            }
            tableContent.push(insightValues);
        }
        return tableContent;
    };

    const tableContent = generateTableContent(getInsightsResponse?.insights ? getInsightsResponse?.insights : []);

    return (
        <div>
            {buttonClicked && (
                <Flashbar
                    items={[
                        {
                            type: statusInfo,
                            content: statusMessage,
                            dismissible: true,
                            dismissLabel: 'Clear',
                            onDismiss: () => {
                                setButtonClicked(false);
                            },
                            id: 'message_1',
                        },
                    ]}
                />
            )}
            <SpaceBetween direction="vertical" size="m">
                <div className="selection-content">
                    <SpaceBetween direction="horizontal" size="xxl">
                        <SpaceBetween direction="vertical" size="xxs">
                            <Header variant="h3">Business</Header>
                            <Select
                                data-testid="business-selection"
                                className={'selects'}
                                placeholder={'Select Business'}
                                selectedOption={businessId}
                                onChange={({detail}) => {
                                    setBusinessId(detail.selectedOption);

                                    const newCountryOptions = Array.from(
                                        new Set(
                                            (auth.authInformation?.businessContexts || [])
                                                .filter(
                                                    (v) =>
                                                        !detail.selectedOption.value || v.businessId === detail.selectedOption.value
                                                )
                                                .map((v) => v.country)
                                        )
                                    ).map((v) => ({label: v, value: v} as SelectProps.Option));

                                    const newFlowOptions = Array.from(
                                        new Set((auth.authInformation?.businessContexts || []).map((v) => v.flow))
                                    ).map((v) => ({label: getBusinessIdDisplayNames(v), value: v} as SelectProps.Option));

                                    setCountryOptions(newCountryOptions);
                                    setCountry(null);
                                    setFlowOptions(newFlowOptions);
                                    setFlow(null);
                                }}
                                options={businessIdOptions}
                                selectedAriaLabel="Selected"
                            />
                        </SpaceBetween>

                        <SpaceBetween direction="vertical" size="xxs">
                            <Header variant="h3">Country</Header>
                            <Select
                                data-testid="country-selection"
                                className={'selects'}
                                placeholder={'Select Country'}
                                selectedOption={country}
                                onChange={({detail}) => {
                                    setCountry(detail.selectedOption);

                                    const newFlowOptions = Array.from(
                                        new Set(
                                            (auth.authInformation?.businessContexts || [])
                                                .filter(
                                                    (bc) =>
                                                        bc.businessId === businessId?.value &&
                                                        bc.country === detail.selectedOption?.value
                                                )
                                                .map((v) => v.flow)
                                        )
                                    ).map((v) => ({label: getBusinessIdDisplayNames(v), value: v} as SelectProps.Option));

                                    setFlowOptions(newFlowOptions);
                                    setFlow(null);
                                }}
                                options={countryOptions}
                                selectedAriaLabel="Selected"
                                disabled={!businessId?.value}
                            />
                        </SpaceBetween>

                        <SpaceBetween direction="vertical" size="xxs">
                            <Header variant="h3">Flow</Header>
                            <Select
                                data-testid="flow-selection"
                                className={'selects'}
                                placeholder={'Select Flow'}
                                selectedOption={flow}
                                onChange={async ({detail}) => {
                                    setFlow(detail.selectedOption);
                                    setForecastHierarchy('');
                                    await auth.authInformation?.switchTo(
                                        businessId?.value ?? '',
                                        country?.value ?? '',
                                        detail.selectedOption?.value ?? ''
                                    );
                                }}
                                options={flowOptions}
                                selectedAriaLabel="Selected"
                                disabled={!businessId?.value && !country?.value}
                            />
                        </SpaceBetween>

                        {getForecastLocationsResponse?.forecastGranularity?.map((granularityType, index) => (
                            <SpaceBetween key={granularityType} direction="vertical" size="xxs">
                                <Header variant="h3">
                                    {granularityType
                                        .split(/(?=[A-Z])/)
                                        .map((type) => type.charAt(0).toUpperCase() + type.slice(1))
                                        .join(' ')}
                                </Header>
                                <Select
                                    data-testid={`${granularityType}-selection`}
                                    className={'selects'}
                                    placeholder={`Select ${granularityType}`}
                                    selectedOption={
                                        {
                                            label: forecastHierarchy.split('-')[index],
                                            value: forecastHierarchy.split('-')[index],
                                        } as SelectProps.Option
                                    }
                                    onChange={({detail}) =>
                                        setForecastHierarchy((currentValue) => {
                                            const forecastHierarchyArray = currentValue.split('-');
                                            forecastHierarchyArray[index] = detail.selectedOption?.value as string;
                                            return forecastHierarchyArray.join('-');
                                        })
                                    }
                                    options={getForecastLocationsResponse?.forecastLocations
                                        ?.map((forecastLocation) => forecastLocation.split('/')[index])
                                        .filter((v, i, a) => a.indexOf(v) === i)
                                        .map(
                                            (value) =>
                                                ({
                                                    label: value,
                                                    value: value,
                                                } as SelectProps.Option)
                                        )}
                                    selectedAriaLabel="Selected"
                                />
                            </SpaceBetween>
                        ))}

                        <SpaceBetween direction="vertical" size="xxs">
                            <Header variant="h3">Date Range</Header>
                            <DateRangePicker
                                data-testid="date-selection"
                                onChange={({detail}) => {
                                    setDateRangeValue(detail.value);
                                }}
                                value={dateRangeValue}
                                relativeOptions={[]}
                                isValidRange={() => ({valid: true})}
                                i18nStrings={{
                                    todayAriaLabel: 'Today',
                                    nextMonthAriaLabel: 'Next month',
                                    previousMonthAriaLabel: 'Previous month',
                                    customRelativeRangeDurationLabel: 'Duration',
                                    customRelativeRangeDurationPlaceholder: 'Enter duration',
                                    customRelativeRangeOptionLabel: 'Custom range',
                                    customRelativeRangeOptionDescription: 'Do NOT use range.',
                                    customRelativeRangeUnitLabel: 'Unit of time',
                                    formatRelativeRange: (e) => {
                                        const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                                        return `Last ${e.amount} ${t}`;
                                    },
                                    formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                                    dateTimeConstraintText: 'Please select valid dates and times in forecast.',
                                    relativeModeTitle: 'Relative range',
                                    absoluteModeTitle: 'Absolute range',
                                    relativeRangeSelectionHeading: 'Choose a range',
                                    startDateLabel: 'Start date',
                                    endDateLabel: 'End date',
                                    startTimeLabel: 'Start time',
                                    endTimeLabel: 'End time',
                                    clearButtonLabel: 'Clear and dismiss',
                                    cancelButtonLabel: 'Cancel',
                                    applyButtonLabel: 'Apply',
                                }}
                                rangeSelectorMode={'absolute-only'}
                                placeholder="Filter by a date and time range"
                                timeOffset={0}
                            />
                        </SpaceBetween>

                        <SpaceBetween direction="vertical" size="xxs">
                            <Header variant="h3">Retrieve Insights</Header>
                            <div className="switch-business-button">
                                <Button
                                    data-testid="insights-button"
                                    disabled={
                                        !businessId?.value ||
                                        !country?.value ||
                                        !flow?.value ||
                                        forecastHierarchy.length === 0 ||
                                        dateRangeValue === undefined
                                    }
                                    onClick={async () => {
                                        setShowTable(true);
                                        await prepareAndGetInsightsUntilFinish();
                                    }}
                                >
                                    Retrieve Insights
                                </Button>
                            </div>
                        </SpaceBetween>
                    </SpaceBetween>
                </div>
                <div className={'insights-table'}>
                    {showTable && (
                        <Table
                            data-testid="insights-table"
                            columnDefinitions={tableColumns}
                            items={tableContent}
                            loadingText="Loading resources"
                            resizableColumns
                            stickyHeader
                            wrapLines
                            loading={getInsightsResponse?.status !== 'success'}
                            header={<Header>{forecastHierarchy} Insights</Header>}
                        />
                    )}
                </div>
            </SpaceBetween>
        </div>
    );
}
