import React, {useMemo, useState} from 'react';
import {Pagination, Table, TextFilter} from '@amzn/awsui-components-react';
import {utils, WorkBook} from 'xlsx';

export default function PreviewTemplateTable(props: {template: WorkBook | undefined; visible: boolean; isCSV?: boolean}) {
    const ITEMS_PER_PAGE = 50;
    const columnDefinitionIndex = props.isCSV ? 0 : 1;

    const generateColumnDefinitions = (data: any[]) => {
        const columnDefinitions: any[] = [];
        for (let col = 0; col < Object.values(data[0]).length; col++) {
            columnDefinitions.push({
                id: Object.values(data[columnDefinitionIndex])[col],
                header: Object.values(data[columnDefinitionIndex])[col],
                cell: (item) => item[col],
                minWidth: '60px',
            });
        }
        return columnDefinitions;
    };

    const generateTableItems = (data: any[]) => {
        const items: any[] = [];
        // skipping first 2 rows
        // row 0: data type. For example: "STRING", "DATE"
        // row 1: column name. For example: "STORE", "BALANCEDATE"
        for (let row = columnDefinitionIndex + 1; row < data.length; row++) {
            items.push(Object.values(data[row]));
        }
        return items;
    };

    const [columnDefinitions, tableItems] = useMemo(() => {
        if (props.template != undefined && props.visible) {
            const sheet_data = utils.sheet_to_json(props.template.Sheets[props.template.SheetNames[0]], {
                raw: false, // translate Date to date string.
                rawNumbers: true, // keep numeric customer_id as it is. e.g. 548671896902
                defval: '', // handle empty cells. e.g. override units/orders
                header: props.isCSV ? 1 : undefined, // formats CSV headers properly, if XLSX then ignore
            });
            return [generateColumnDefinitions(sheet_data), generateTableItems(sheet_data)];
        }
        return [[], []];
    }, [props.template]);

    const [page, setPage] = useState(1);
    const [filteringText, setFilteringText] = React.useState('');

    const tableItemFiltered = tableItems.filter((row) => {
        if (filteringText === '') return true;
        const filterStrings = filteringText.split(' ').filter((str) => str !== '');
        return filterStrings.every((filterString) => row.join('_').includes(filterString));
    });

    const tableItemToShow = tableItemFiltered.slice(ITEMS_PER_PAGE * (page - 1), ITEMS_PER_PAGE * page);

    return (
        <Table
            data-testid="template-file-preview"
            loadingText="Loading template"
            loading={props.template == undefined}
            trackBy={(row) => row.join('_')}
            columnDefinitions={columnDefinitions}
            items={tableItemToShow}
            filter={
                <TextFilter
                    filteringPlaceholder="Find overrides"
                    filteringAriaLabel="Filter Overrides"
                    filteringText={filteringText}
                    onChange={({detail}) => {
                        setFilteringText(detail.filteringText);
                        setPage(1);
                    }}
                />
            }
            pagination={
                <Pagination
                    currentPageIndex={page}
                    pagesCount={Math.ceil(tableItemFiltered.length / ITEMS_PER_PAGE)}
                    ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
                    }}
                    onChange={({detail}) => setPage(detail.currentPageIndex)}
                />
            }
        />
    );
}
