import {Box, Button, FormField, Input, Modal, SpaceBetween, Select, Spinner} from '@amzn/awsui-components-react';
import React, {useEffect, useState} from 'react';
import {EventType, ForecastStartDate} from '../operational-dashboard/view-operational-dashboard';

interface ConfirmationModalProps {
    visible: boolean;
    promptHeader: string;
    prompt: string;
    confirmPhrase: string;
    additionalInfo?: string;
    onConfirm: () => void;
    onCancel: () => void;
    loading?: boolean;
    showCancel?: boolean;
    showInput?: boolean;
    eventType?: EventType;
    modelGroupNameForGeneration?: string;
    updateModelGroupName?: (string) => void;
    defaultForecastStartDateForGeneration?: string;
    updateForecastStartDate?: (string) => void;
}

export function ConfirmationModal(props: ConfirmationModalProps) {
    const defaultProps = {
        showCancel: props.showCancel ?? true,
        showInput: props.showInput ?? true,
    };
    props = {...props, ...defaultProps};
    const [message, setMessage] = useState<string>('');
    const [forecastStartDateSelectedOption, setForecastStartDateSelectedOption] = useState({
        label: '',
        value: '',
    });
    const [alternativeForecastStartDateForGeneration, setAlternativeForecastStartDateForGeneration] = useState('');

    useEffect(() => {
        if (props.updateForecastStartDate) {
            props.updateForecastStartDate(props.defaultForecastStartDateForGeneration);
        }

        setForecastStartDateSelectedOption({
            label: `${props.defaultForecastStartDateForGeneration} (Default)`,
            value: props.defaultForecastStartDateForGeneration || '',
        });

        setAlternativeForecastStartDateForGeneration(
            props.defaultForecastStartDateForGeneration === ForecastStartDate.at_start_of_week_sunday_zero_hour
                ? ForecastStartDate.at_start_of_next_week_sunday_zero_hour
                : ForecastStartDate.at_start_of_week_sunday_zero_hour
        );
    }, [props.visible]);

    function onCancel() {
        setMessage('');
        props.onCancel();
    }

    function onConfirm() {
        setMessage('');
        props.onConfirm();
    }

    if (props.loading) {
        return (
            <Modal
                onDismiss={onCancel}
                visible={props.visible}
                size="medium"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            {props.showCancel && (
                                <Button data-testid="cancel-button" variant="link" onClick={onCancel}>
                                    Cancel
                                </Button>
                            )}
                            <Button
                                data-testid="ok-button"
                                variant="primary"
                                onClick={onConfirm}
                                disabled={message.localeCompare(props.confirmPhrase) !== 0}
                            >
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={props.promptHeader}
            >
                <Box data-testid="confirmation-modal-loading" textAlign="center">
                    <Spinner />
                    Loading
                </Box>
            </Modal>
        );
    }

    return (
        <Modal
            onDismiss={onCancel}
            visible={props.visible}
            size="medium"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        {props.showCancel && (
                            <Button data-testid="cancel-button" variant="link" onClick={onCancel}>
                                Cancel
                            </Button>
                        )}
                        <Button
                            data-testid="ok-button"
                            variant="primary"
                            onClick={onConfirm}
                            disabled={message.localeCompare(props.confirmPhrase) !== 0}
                        >
                            Ok
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={props.promptHeader}
        >
            {props.eventType === EventType.Generate && (
                <FormField label={<span>ModelGroupName</span>}>
                    <Input
                        data-testid="model-group-name-input"
                        onChange={({detail}) => props.updateModelGroupName!(detail.value)}
                        value={props.modelGroupNameForGeneration!}
                        placeholder={props.modelGroupNameForGeneration}
                    />
                </FormField>
            )}
            {props.eventType === EventType.Generate && (
                <FormField label={<span>ForecastStartDate</span>}>
                    <Select
                        data-testid="forecast-start-date-input"
                        selectedOption={forecastStartDateSelectedOption}
                        onChange={({detail}) => {
                            props.updateForecastStartDate!(detail.selectedOption.value);
                            setForecastStartDateSelectedOption({
                                label: detail.selectedOption.label || '',
                                value: detail.selectedOption.value || '',
                            });
                        }}
                        options={[
                            {
                                label: `${props.defaultForecastStartDateForGeneration} (Default)`,
                                value: props.defaultForecastStartDateForGeneration,
                            },
                            {
                                label: alternativeForecastStartDateForGeneration,
                                value: alternativeForecastStartDateForGeneration,
                            },
                        ]}
                        selectedAriaLabel="Selected"
                    />
                </FormField>
            )}
            {props.showInput && (
                <FormField
                    label={props.prompt}
                    description={
                        <span>
                            If yes, type the word <b>{props.confirmPhrase}</b>
                        </span>
                    }
                    constraintText={props.additionalInfo}
                >
                    <Input
                        data-testid="confirm-input"
                        onChange={({detail}) => setMessage(detail.value)}
                        value={message}
                        placeholder={props.confirmPhrase}
                    />
                </FormField>
            )}
            {!props.showInput && <p>{props.prompt}</p>}
        </Modal>
    );
}
