import React, {useEffect, useRef, useState, memo} from 'react';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {useRouter} from '../hooks/use-router';
import {useRetrieveAggregatedRecords, useRetrieveForecast} from '../hooks/use-analytics-api';
import {Notification} from '../navigation/page-layout';
import {translateErrorToReactNode} from '../common';
import {useCollection} from '@amzn/awsui-collection-hooks';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    DateRangePicker,
    DateRangePickerProps,
    Header,
    Input,
    LineChart,
    MixedLineBarChartProps,
    Modal,
    Multiselect,
    Pagination,
    Select,
    SpaceBetween,
    Table,
    TextContent,
} from '@amzn/awsui-components-react';
import {recordUserEvent} from '../common/portal-analytics';
import {PortalEventName} from '../common/portal-event-name-enum';
import {useApplyOverridesToTemplate} from '../hooks/use-forecast-store-api';

type ForecastRecord = {
    time: string;
    node: string;
    orders: number;
    units: number;
    granularity: Record<string, string>;
    reasonCode: string;
    reason?: string;
};

type LineSeries = MixedLineBarChartProps.LineDataSeries<string>;

const FormattedInteger = ({value}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
    });

    return <span>{formatter.format(value)}</span>;
};

export interface ForecastViewProps {
    timeGranularity: 'weekly' | 'daily';
    pushNotification: (notification: Notification) => void;
}

const reasonCodeOptions = [
    {label: 'Gut instinct', value: 'Gut instinct'},
    {label: 'Leadership request', value: 'Leadership request'},
    {label: 'New store', value: 'New store'},
    {label: 'Sporting event', value: 'Sporting event'},
    {label: 'Weather event', value: 'Weather event'},
    {label: 'Competitor closure', value: 'Competitor closure'},
    {label: 'BYOF', value: 'BYOF'},
    {label: 'Other', value: 'Other'},
];

function isDateBetweenRange(date, startDate, endDate) {
    if (!date || !startDate || !endDate) return true;
    const parsedDate = new Date(date);
    const parsedStartDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);

    return parsedDate >= parsedStartDate && parsedDate <= parsedEndDate;
}

function formatLineChartValues(e) {
    return Math.abs(e) >= 1e6
        ? (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
        : Math.abs(e) >= 1e3
        ? (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
        : e.toFixed(2);
}

export function ForecastView(props: ForecastViewProps) {
    console.log(`loading ${props.timeGranularity} view...`);

    const auth = useAuth();
    const router = useRouter();
    const initialized = useRef(false);
    const isLoadingGrid = useRef<boolean>(true);
    const isLoadingCharts = useRef<boolean>(true);
    const [isApplyingOverrides, setIsApplyingOverrides] = useState<boolean>(false);
    const itemsPerPage = 100;
    const [nodes, setNodes] = useState<string[]>([]);
    const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
    const [balanceWeeks, setBalanceWeeks] = useState<string[]>([]);
    const [selectedBalanceWeeks, setSelectedBalanceWeeks] = useState<string[]>([]);
    const [forecastRecords, setForecastRecords] = useState<ForecastRecord[]>([]);
    const [editedForecastRecords, setEditedForecastRecords] = useState<ForecastRecord[]>([]);
    const [dateRange, setDateRange] = useState<DateRangePickerProps.Value | null>();
    const [lineSeries, setLineSeries] = useState<LineSeries[][]>([[]]);
    const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);

    const tenant = {
        business: auth.authInformation!.current!.businessId,
        country: auth.authInformation!.current!.country,
        flow: auth.authInformation!.current!.flow,
    };

    const momModelName = 'mom';
    const defaultModelName = auth.authInformation!.current!.configurationData?.modelName;

    const modelNamesConfig = [
        {
            modelName: 'mom',
            friendlyName: 'MOM',
        },
        {
            modelName: defaultModelName,
            friendlyName: 'Baseline',
        },
    ];

    const defaultLogisticType = 'delivery';

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ExplainabilityView);
    const forecastStoreClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreEdit);

    const {
        execute: excuteRetrieveForecast,
        status: statusForecast,
        value: getForecastResponse,
    } = useRetrieveForecast(clientConfiguration, createErrorListener('RetrieveForecast failed'), [auth]);

    const {
        execute: excuteRetrieveAggregatedRecords,
        status: statusAggregatedRecords,
        value: getAggregatedRecordsResponse,
    } = useRetrieveAggregatedRecords(clientConfiguration, createErrorListener('RetrieveAggregatedRecords failed'), [auth]);

    isLoadingGrid.current = statusForecast === 'idle' || statusForecast === 'pending';
    isLoadingCharts.current = statusAggregatedRecords === 'idle' || statusAggregatedRecords === 'pending';

    const {execute: executeApplyOverridesToTemplate} = useApplyOverridesToTemplate(
        forecastStoreClientConfiguration,
        createErrorListener('ApplyOverridesToTemplate failed'),
        [auth]
    );

    if (!initialized.current) {
        recordUserEvent({
            eventData: {
                ...tenant,
                email: auth.authInformation!.email,
                routerPathName: router.pathname,
                pageName: `Forecast Builder - ${props.timeGranularity}`,
                loginTimeStamp: new Date().toLocaleString(),
            },
            eventName: PortalEventName.pageVisited,
        });

        excuteRetrieveForecast({
            ...tenant,
            forecastType: 'sop',
            filter: JSON.stringify({
                modelNames: [momModelName],
                forecastViewType: props.timeGranularity,
                logisticType: defaultLogisticType,
            }),
            flattened: true,
            // We set the limit to 10000 for now, AFO US OB can retrieve all records at once (for WFM, we need to do server-side pagination)
            limit: 10000,
        });

        excuteRetrieveAggregatedRecords({
            ...tenant,
            forecastType: 'sop',
            filter: JSON.stringify({
                modelNames: [momModelName, defaultModelName],
                forecastViewType: props.timeGranularity,
                logisticType: defaultLogisticType,
                isWrap: true,
            }),
        });

        initialized.current = true;
    }

    useEffect(() => {
        if (!isLoadingCharts.current && getAggregatedRecordsResponse?.aggregatedRecords) {
            if (!getAggregatedRecordsResponse.aggregatedRecords['cd_actuals']) {
                setLineSeries([[]]);
                return;
            }

            const lineSeriesOrders: LineSeries[] = [
                {
                    type: 'line',
                    title: 'CD',
                    data: getAggregatedRecordsResponse.aggregatedRecords['cd_actuals'].map((item) => ({
                        x: item.time,
                        y: item.orders,
                    })),
                    color: '#FFA500',
                    valueFormatter: formatLineChartValues,
                },
                ...(modelNamesConfig.map((item) => ({
                    type: 'line',
                    title: `${item.friendlyName} Forecast`,
                    data: getAggregatedRecordsResponse.aggregatedRecords[`${item.modelName}_forecast`].map((item) => ({
                        x: item.time,
                        y: item.orders,
                    })),
                    valueFormatter: formatLineChartValues,
                })) as LineSeries[]),
            ];
            const lineSeriesUnits: LineSeries[] = [
                {
                    type: 'line',
                    title: 'CD',
                    data: getAggregatedRecordsResponse.aggregatedRecords['cd_actuals'].map((item) => ({
                        x: item.time,
                        y: item.units,
                    })),
                    color: '#FFA500',
                    valueFormatter: formatLineChartValues,
                },
                ...(modelNamesConfig.map((item) => ({
                    type: 'line',
                    title: `${item.friendlyName} Forecast`,
                    data: getAggregatedRecordsResponse.aggregatedRecords[`${item.modelName}_forecast`].map((item) => ({
                        x: item.time,
                        y: item.units,
                    })),
                    valueFormatter: formatLineChartValues,
                })) as LineSeries[]),
            ];
            setLineSeries([lineSeriesOrders, lineSeriesUnits]);
        }
    }, [isLoadingCharts.current]);

    useEffect(() => {
        if (!isLoadingGrid.current && getForecastResponse?.records && getForecastResponse.records.length > 0) {
            /*
             * We only show the MoM forecast in the table for now
             */
            const records = getForecastResponse.records
                .filter((item) => item.modelName === momModelName)
                .map(
                    (item) =>
                        ({
                            time: item.balanceDate ?? item.balanceWeek,
                            node: item.node,
                            orders: item.orders,
                            units: item.units,
                            granularity: item.granularity,
                            reasonCode: 'BYOF',
                            reason: 'Month over month forecast',
                        } as ForecastRecord)
                );
            setForecastRecords(records);

            const uniqueNodes: string[] = Array.from(new Set(getForecastResponse.records.map((record) => record.node)));
            setNodes(uniqueNodes);
            setSelectedNodes(uniqueNodes);
            const uniqueBalanceWeeks = Array.from(new Set(getForecastResponse.records.map((record) => record.balanceWeek!)));
            setBalanceWeeks(uniqueBalanceWeeks);
        }
    }, [isLoadingGrid.current]);

    const empty = (
        <Box textAlign="center" color="inherit">
            <b>No matching data</b>
            <Box variant="p" color="inherit">
                There is no matching data to display
            </Box>
        </Box>
    );

    const mergedRecords = forecastRecords.map((record) => {
        // joins forecast records and edited records
        const editedRecord = editedForecastRecords.find(
            (editedRecord) => editedRecord.time === record.time && editedRecord.node === record.node
        );
        return editedRecord ?? record;
    });

    const {allPageItems, items, actions, collectionProps, paginationProps} = useCollection(mergedRecords, {
        filtering: {
            empty: empty,
            noMatch: empty,
            filteringFunction(item) {
                if (!selectedNodes.includes(item.node)) return false;
                else if (selectedBalanceWeeks.length && props.timeGranularity === 'weekly')
                    return selectedBalanceWeeks.includes(item.time);
                else if (dateRange && props.timeGranularity === 'daily' && dateRange.type === 'absolute')
                    return isDateBetweenRange(item.time, dateRange.startDate, dateRange.endDate);
                return true;
            },
        },
        pagination: {pageSize: itemsPerPage},
        sorting: {},
        selection: {
            keepSelection: true,
        },
    });

    const {selectedItems} = collectionProps;

    function onForecastRecordEdit(row, column, newValue) {
        const item = {...row};

        // checks if record has already been edited, if so, update it. else append it
        setEditedForecastRecords((records) => {
            const existingItemIndex = records.findIndex((record) => record.time === item.time && record.node === item.node);
            item[column.id] = newValue;
            if (existingItemIndex !== -1) {
                const updatedRecords = [...records];
                updatedRecords[existingItemIndex] = item;
                return updatedRecords;
            } else {
                return [...records, item];
            }
        });

        // checks if item is already selected, if so, edit it. else append it
        const existedSelectedItemIndex = selectedItems!.findIndex(
            (record) => record.time === item.time && record.node === item.node
        );
        if (existedSelectedItemIndex !== -1) {
            const records = [...selectedItems!];
            records[existedSelectedItemIndex] = item;
            actions.setSelectedItems(records);
        } else {
            actions.setSelectedItems([...selectedItems!, item]);
        }
    }

    function discardAllForecastRecordEdits() {
        setEditedForecastRecords([]);
        actions.setSelectedItems([]);
    }

    function resetSelectedRows() {
        const selectedEditedRecords = editedForecastRecords.filter((item) => !selectedItems!.includes(item));
        setEditedForecastRecords(selectedEditedRecords);
        actions.setSelectedItems(selectedItems!.filter((item) => forecastRecords.includes(item)));
    }

    useEffect(() => {
        actions.setSelectedItems(selectedItems!.filter((item) => selectedNodes.includes(item.node)));
    }, [selectedNodes]);

    useEffect(() => {
        actions.setSelectedItems(selectedItems!.filter((item) => selectedBalanceWeeks.includes(item.time)));
    }, [selectedBalanceWeeks]);

    return (
        <React.Fragment>
            <SpaceBetween size="s">
                <ColumnLayout columns={2}>
                    <div>
                        <Multiselect
                            selectedOptions={selectedNodes.map((item) => ({
                                label: item,
                                value: item,
                            }))}
                            onChange={async ({detail}) => {
                                const nodes = detail.selectedOptions.map((item) => item.value!);
                                setSelectedNodes(nodes);
                                await excuteRetrieveAggregatedRecords({
                                    ...tenant,
                                    forecastType: 'sop',
                                    filter: JSON.stringify({
                                        modelNames: [momModelName, defaultModelName],
                                        forecastViewType: props.timeGranularity,
                                        logisticType: defaultLogisticType,
                                        isWrap: true,
                                        nodes: nodes.join('|'),
                                    }),
                                });
                            }}
                            options={nodes.map((item) => ({
                                label: item,
                                value: item,
                            }))}
                            tokenLimit={5}
                            filteringType="auto"
                            placeholder="Select nodes/stores"
                            data-testid="nodes-multiselect"
                        />
                    </div>
                    <div>
                        <ColumnLayout columns={2}>
                            <div>
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        onClick={async (detail) => {
                                            setSelectedNodes(nodes);
                                            await excuteRetrieveAggregatedRecords({
                                                ...tenant,
                                                forecastType: 'sop',
                                                filter: JSON.stringify({
                                                    modelNames: [momModelName, defaultModelName],
                                                    forecastViewType: props.timeGranularity,
                                                    logisticType: defaultLogisticType,
                                                    isWrap: true,
                                                }),
                                            });
                                        }}
                                        data-testid="select-all-nodes-button"
                                    >
                                        Select All
                                    </Button>
                                    <Button
                                        onClick={async (detail) => {
                                            setSelectedNodes([]);
                                            await excuteRetrieveAggregatedRecords({
                                                ...tenant,
                                                forecastType: 'sop',
                                                filter: JSON.stringify({
                                                    forecastViewType: props.timeGranularity,
                                                    logisticType: defaultLogisticType,
                                                    isWrap: true,
                                                }),
                                            });
                                        }}
                                        data-testid="deselect-all-nodes-button"
                                    >
                                        De-select All
                                    </Button>
                                </SpaceBetween>
                            </div>
                        </ColumnLayout>
                    </div>
                </ColumnLayout>

                <Container>
                    {' '}
                    <SpaceBetween size="s">
                        <ColumnLayout columns={2}>
                            <div>
                                <LineChart
                                    height={300}
                                    series={lineSeries![0]}
                                    hideFilter
                                    xScaleType="categorical"
                                    yTitle="Orders"
                                    xTitle={props.timeGranularity === 'weekly' ? 'Balance Week' : 'Balance Date'}
                                    loadingText="Loading..."
                                    yTickFormatter={formatLineChartValues}
                                    empty={empty}
                                ></LineChart>
                            </div>
                            <div>
                                <LineChart
                                    height={300}
                                    series={lineSeries![1]}
                                    hideFilter
                                    xScaleType="categorical"
                                    yTitle="Units"
                                    xTitle={props.timeGranularity === 'weekly' ? 'Balance Week' : 'Balance Date'}
                                    loadingText="Loading..."
                                    yTickFormatter={formatLineChartValues}
                                    empty={empty}
                                ></LineChart>
                            </div>
                        </ColumnLayout>
                    </SpaceBetween>
                </Container>
                <div>
                    <Table
                        {...collectionProps}
                        stickyHeader
                        stripedRows
                        selectionType="multi"
                        filter={
                            <React.Fragment>
                                <ColumnLayout columns={2}>
                                    {props.timeGranularity == 'weekly' ? (
                                        <Multiselect
                                            selectedOptions={selectedBalanceWeeks.map((item) => ({
                                                label: item,
                                                value: item,
                                            }))}
                                            data-testid="balance-week-multiselect"
                                            onChange={async ({detail}) => {
                                                const weeks = detail.selectedOptions.map((item) => item.value!);
                                                setSelectedBalanceWeeks(weeks);
                                            }}
                                            options={balanceWeeks.map((item) => ({
                                                label: item,
                                                value: item,
                                            }))}
                                            tokenLimit={5}
                                            filteringType="auto"
                                            placeholder="Select Balance Weeks"
                                        />
                                    ) : (
                                        <DateRangePicker
                                            onChange={({detail}) => setDateRange(detail.value)}
                                            value={dateRange!}
                                            data-testid="balance-date-range-picker"
                                            isValidRange={(range) => {
                                                if (range?.type === 'absolute') {
                                                    const [startDateWithoutTime] = range.startDate.split('T');
                                                    const [endDateWithoutTime] = range.endDate.split('T');
                                                    if (!startDateWithoutTime || !endDateWithoutTime) {
                                                        return {
                                                            valid: false,
                                                            errorMessage:
                                                                'The selected date range is incomplete. Select a start and end date for the date range.',
                                                        };
                                                    }
                                                    if (new Date(range.startDate) > new Date(range.endDate)) {
                                                        return {
                                                            valid: false,
                                                            errorMessage:
                                                                'The selected date range is invalid. The start date must be before the end date.',
                                                        };
                                                    }
                                                }
                                                return {valid: true};
                                            }}
                                            dateOnly={true}
                                            relativeOptions={[]}
                                            placeholder="Filter by a date range"
                                            rangeSelectorMode="absolute-only"
                                            i18nStrings={{
                                                applyButtonLabel: 'Apply',
                                                cancelButtonLabel: 'Cancel',
                                                clearButtonLabel: 'Clear and dismiss',
                                            }}
                                        />
                                    )}
                                </ColumnLayout>
                                <TextContent>
                                    <small>Applying filters may remove previously selected records</small>
                                </TextContent>
                            </React.Fragment>
                        }
                        header={
                            <Header
                                counter={
                                    selectedItems!.length
                                        ? `(${selectedItems!.length}/${
                                              allPageItems.filter((item) => selectedNodes.includes(item.node)).length
                                          })`
                                        : `(${allPageItems.filter((item) => selectedNodes.includes(item.node)).length})`
                                }
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button
                                            data-testid="select-all-rows-button"
                                            onClick={() => actions.setSelectedItems(allPageItems)}
                                        >
                                            Select All
                                        </Button>
                                        <Button data-testid="deselect-all-rows-button" onClick={() => actions.setSelectedItems([])}>
                                            De-select All
                                        </Button>
                                        <Button
                                            variant="normal"
                                            disabled={!selectedItems?.length}
                                            data-testid="discard-selected-changes-button"
                                            onClick={resetSelectedRows}
                                        >
                                            Reset Selected Rows
                                        </Button>
                                        <Button
                                            variant="normal"
                                            disabled={!editedForecastRecords.length}
                                            data-testid="discard-changes-button"
                                            onClick={discardAllForecastRecordEdits}
                                        >
                                            Discard All Changes
                                        </Button>
                                        <Button
                                            variant="primary"
                                            disabled={!selectedItems?.length}
                                            data-testid="open-modal-button"
                                            onClick={({detail}) => {
                                                setConfirmModalVisible(true);
                                            }}
                                        >
                                            Apply Selections as Overrides
                                        </Button>
                                    </SpaceBetween>
                                }
                            >
                                Select Items as Overrides
                            </Header>
                        }
                        pagination={<Pagination {...paginationProps} />}
                        items={items}
                        submitEdit={onForecastRecordEdit}
                        contentDensity="compact"
                        columnDefinitions={[
                            {
                                id: 'node',
                                header: 'Node',
                                cell: (item: ForecastRecord) => item.node,
                                isRowHeader: true,
                                sortingField: 'node',
                            },
                            {
                                id: 'time',
                                header: props.timeGranularity === 'weekly' ? 'Balance Week' : 'Balance Date',
                                cell: (item: ForecastRecord) => item.time,
                                isRowHeader: true,
                                sortingField: 'time',
                            },
                            {
                                id: 'logisticsType',
                                header: 'Logistics Type',
                                cell: (item: ForecastRecord) => item.granularity['logisticType'],
                                isRowHeader: true,
                            },
                            {
                                id: 'orders',
                                header: 'Orders',
                                cell: (item: ForecastRecord) => (
                                    <React.Fragment>{<FormattedInteger value={item.orders ?? 0} />}</React.Fragment>
                                ),
                                isRowHeader: true,
                                editConfig: {
                                    editingCell: (item, {currentValue, setValue}) => {
                                        return (
                                            <Input
                                                type="number"
                                                autoFocus={true}
                                                value={currentValue ?? item.orders}
                                                onChange={(event) => setValue(event.detail.value)}
                                            />
                                        );
                                    },
                                },
                            },
                            {
                                id: 'units',
                                header: 'Units',
                                cell: (item: ForecastRecord) => (
                                    <React.Fragment>{<FormattedInteger value={item.units ?? 0} />}</React.Fragment>
                                ),
                                isRowHeader: true,
                                editConfig: {
                                    editingCell: (item, {currentValue, setValue}) => {
                                        return (
                                            <Input
                                                type="number"
                                                autoFocus={true}
                                                value={currentValue ?? item.units}
                                                onChange={(event) => setValue(event.detail.value)}
                                            />
                                        );
                                    },
                                },
                            },
                            {
                                id: 'reasonCode',
                                header: 'Reason Code',
                                cell: (item: ForecastRecord) => item.reasonCode,
                                isRowHeader: true,
                                editConfig: {
                                    editingCell: (item, {currentValue, setValue}) => {
                                        const value = currentValue ?? item.reasonCode;
                                        return (
                                            <Select
                                                options={reasonCodeOptions}
                                                onChange={({detail}) => {
                                                    setValue(detail.selectedOption.value);
                                                }}
                                                expandToViewport={true}
                                                autoFocus={true}
                                                selectedOption={reasonCodeOptions.find((option) => option.value === value) ?? null}
                                            />
                                        );
                                    },
                                },
                            },
                            {
                                id: 'reason',
                                header: 'Reason',
                                cell: (item: ForecastRecord) => item.reason,
                                isRowHeader: true,
                                editConfig: {
                                    editingCell: (item, {currentValue, setValue}) => {
                                        return (
                                            <Input
                                                type="text"
                                                autoFocus={true}
                                                value={currentValue ?? item.reason}
                                                onChange={(event) => setValue(event.detail.value)}
                                            />
                                        );
                                    },
                                },
                            },
                        ]}
                        loading={isLoadingGrid.current}
                        loadingText="Loading..."
                        empty={empty}
                    ></Table>
                </div>
            </SpaceBetween>
            <Modal
                data-testid="apply-overrides-modal"
                onDismiss={() => setConfirmModalVisible(false)}
                visible={confirmModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                disabled={isApplyingOverrides}
                                variant="link"
                                onClick={({detail}) => {
                                    setConfirmModalVisible(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-testid="submit-button"
                                variant="primary"
                                disabled={isApplyingOverrides}
                                onClick={async ({detail}) => {
                                    setIsApplyingOverrides(true);
                                    await executeApplyOverridesToTemplate({
                                        businessId: tenant.business,
                                        country: tenant.country,
                                        flow: tenant.flow,
                                        timeGranularity: props.timeGranularity,
                                        records: selectedItems!.map((item) => [
                                            /*
                                             * TODO: make this more generic, e.g. we could take the order of items (forecast hierarchy)
                                             * from the bulk override template definition via bulk override API
                                             */
                                            item.node,
                                            item.time,
                                            item.granularity['logisticType'],
                                            item.orders.toString(),
                                            item.units.toString(),
                                            item.reasonCode,
                                            item.reason ?? '',
                                        ]),
                                    });
                                    setIsApplyingOverrides(false);
                                    setConfirmModalVisible(false);
                                    discardAllForecastRecordEdits();
                                    props.pushNotification({
                                        content: 'Overrides have been submitted for processing.',
                                        type: 'info',
                                    });
                                }}
                            >
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Apply Selections as Overrides"
            >
                {isApplyingOverrides
                    ? 'Overrides are being submitted for processing...'
                    : 'Are you sure you want to apply your selections as overrides to the forecast?'}

                <TextContent>
                    <small>Modified rows will be reset after submission.</small>
                </TextContent>
            </Modal>
        </React.Fragment>
    );
}

export default memo(ForecastView);
